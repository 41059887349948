import React from "react";
import { compose } from "redux";
import { connect } from "react-redux";
import { Field, reduxForm } from "redux-form";
import { CheckCircleIcon, MinusCircleIcon } from "@heroicons/react/solid";

import { renderField } from "../../utils/FieldComponents";
import { addExercise, deleteExercise } from "../../../actions/ProgramActions";
import CustomButton from "../../../components/CustomButton";

const validate = (values, props) => {
  const id = props.form;
  const errors = { [id]: {} };
  //check that exercise name is not empty
  if (values[id] && !values[id].exercise) {
    errors[id].exercise = "Required";
  }
  return errors;
};

const ExerciseEditForm = ({
  handleSubmit,
  addExercise,
  deleteExercise,
  id,
}) => {
  const onSubmit = async (formValues) => {
    const exerciseObject = formValues[id];
    addExercise(exerciseObject);
  };

  const onDeleteExercise = () => {
    deleteExercise(id);
  };

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      className="w-full flex justify-start"
    >
      <div className="w-4/5 flex gap-2 justify-between border-b border-brown  p-2 mb-2">
        <div className="w-full">
          <Field
            name={`${id}.number`}
            type="number"
            min={1}
            component={renderField}
            label="Order"
          />
        </div>
        <div className="w-full">
          <Field
            name={`${id}.exercise`}
            type="text"
            component={renderField}
            label="Exercise name"
          />
        </div>
        <div className="w-full">
          <Field
            name={`${id}.reps`}
            type="number"
            min={1}
            component={renderField}
            label="Reps"
          />
        </div>
        <div className="w-full">
          <Field
            name={`${id}.sets`}
            type="number"
            min={1}
            component={renderField}
            label="Sets"
          />
        </div>
        <div className="w-full">
          <Field
            name={`${id}.rest`}
            type="number"
            min={1}
            component={renderField}
            label="Rest time"
          />
        </div>
      </div>
      <div className="flex w-min">
        <CustomButton
          type="submit"
          buttonText={""}
          icon={
            <CheckCircleIcon
              className="block w-8 text-lightBrown hover:text-normalGreen"
              aria-hidden="true"
            />
          }
        />
        <CustomButton
          type="button"
          buttonStyle="py-0"
          buttonText={""}
          icon={
            <MinusCircleIcon
              className="block w-8 text-lightRed hover:text-red"
              aria-hidden="true"
            />
          }
          handleClick={onDeleteExercise}
        />
      </div>
    </form>
  );
};

//sets unique form name for each exercise edit form
const mapStateToProps = (state, ownProps) => {
  return {
    form: ownProps.id,
  };
};

export default compose(
  connect(mapStateToProps, { addExercise, deleteExercise }),
  reduxForm({ validate })
)(ExerciseEditForm);
