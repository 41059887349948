import React, { useEffect, useState } from "react";
import _ from "lodash";
import { useParams } from "react-router-dom";
import { connect } from "react-redux";
import {
  PencilAltIcon,
  TrashIcon,
  RefreshIcon,
  XCircleIcon,
} from "@heroicons/react/outline";
import { Disclosure } from "@headlessui/react";

import DayTemplate from "../forms/programs/pageTwoComponents/dayView/DayTemplate";
import WeekTemplate from "../forms/programs/pageTwoComponents/weekView/WeekTemplate";
import MonthTemplate from "../forms/programs/pageTwoComponents/monthView/MonthTemplate";
import CustomButton from "../components/CustomButton";
import {
  getProgram,
  loadExercises,
  loadEditValues,
  editProgram,
  deleteProgram,
} from "../actions/ProgramActions";
import { httpUpdateProgram } from "../apis/requestProgram";
import ReviewForm from "../forms/ReviewForm";
import ReviewCard from "../components/ReviewCard";
import ErrorMessage from "../components/ErrorMessage";
import LoadingScreen from "../components/LoadingScreen";
import { ExportCSV } from "../components/ExportCSV";

//editUploadedProgram,
function Program({
  getProgram,
  exercises,
  loadExercises,
  loadEditValues,
  editValues,
  editProgram,
  username,
  isSignedIn,
  deleteProgram,
}) {
  const [program, setProgram] = useState({});
  const [updateFlag, setUpdateFlag] = useState(false);
  const [errorFlag, setErrorFlag] = useState("");
  let { id } = useParams();

  useEffect(() => {
    const loadProgram = async () => {
      //get program and exercises related to program
      const response = await getProgram(id);
      //set program if empty
      if (_.isEmpty(program, true)) {
        setProgram(response);
      }
    };
    loadProgram();
  }, [getProgram, id, program]);

  const updateProgram = () => {
    if (
      editValues.programTitle &&
      editValues.description &&
      editValues.description.length >= 15
    ) {
      httpUpdateProgram(id, editValues);
      window.location.reload(true);
    } else if (editValues.description.length < 15) {
      setErrorFlag("Description must be at least 15 characters.");
    } else {
      setErrorFlag("Title and description may not be empty.");
    }
  };

  const onDeleteProgram = () => {
    deleteProgram(id);
  };

  const displayView = () => {
    let workouts = [];
    if (exercises) {
      //save exercises to program form which is used by template to display workouts
      loadExercises(exercises);
      //determine workout dates based on exercises' date
      // eslint-disable-next-line no-unused-vars
      for (const [key, value] of Object.entries(exercises)) {
        if (!workouts.includes(value.date)) {
          workouts.push(value.date);
        }
      }
    }
    switch (program.timePeriod) {
      case "week":
        return <WeekTemplate previewFlag={true} />;
      case "month":
        return <MonthTemplate previewFlag={true} />;
      default:
        return <DayTemplate previewFlag={true} workoutDates={workouts} />;
    }
  };

  const startUpdate = () => {
    //load in program values to edit program form
    loadEditValues({
      programTitle: program.programTitle,
      description: program.description,
    });
    setUpdateFlag(!updateFlag);
  };

  //temporary loading screen
  if (_.isEmpty(program, true)) {
    return <LoadingScreen />;
  }

  const creationInfo = () => {
    return (
      <div className="flex items-center justify-between">
        <span>
          Author:
          <a href={`/`} className="text-darkGreen font-bold">
            {" "}
            {program.author}
          </a>
        </span>
        <div className="flex flex-col">
          <span>Created: {new Date(program.createdAt).toDateString()}</span>
          <span>Last edited: {new Date(program.updatedAt).toDateString()}</span>
        </div>
      </div>
    );
  };

  const handleInputChange = (newProgramValue, keyValue) => {
    editProgram({
      newProgramValue: newProgramValue,
      key: keyValue,
    });
  };

  return (
    <div className="min-h-screen h-max pb-4 flex justify-center">
      <div className="flex flex-col w-full items-center">
        <div className="w-2/3 bg-whiteBrown p-4 mt-8 rounded h-max">
          <div className="w-full flex flex-col gap-8">
            {!updateFlag ? (
              <h1 className="uppercase text-2xl flex justify-center border-b-2 border-brown">
                {program.programTitle}
              </h1>
            ) : (
              <input
                type="text"
                value={editValues.programTitle}
                className="uppercase text-2xl flex justify-center border-b-2 border-brown"
                onChange={(e) =>
                  handleInputChange(e.target.value, "programTitle")
                }
              />
            )}
            {program.file.length > 0 && (
              <div className="flex flex-col items-center w-full">
                <img
                  className="max-h-96 rounded"
                  src={process.env.REACT_APP_IMAGE_URL + program.file}
                  alt=""
                />
              </div>
            )}
            {creationInfo()}
            {program.author === username && !updateFlag && (
              <div className="flex">
                <PencilAltIcon
                  className="block w-8 text-lightBrown hover:text-brown"
                  aria-hidden="true"
                  onClick={() => startUpdate()}
                />
                <TrashIcon
                  className="block w-8 text-lightRed hover:text-red"
                  aria-hidden="true"
                  onClick={() => onDeleteProgram()}
                />
              </div>
            )}
            {program.author === username && updateFlag && (
              <div className="flex flex-col gap-2">
                {errorFlag && <ErrorMessage text={errorFlag} />}
                <div className="flex gap-2">
                  <CustomButton
                    buttonStyle="bg-lightGreen hover:bg-normalGreen hover:text-white text-black"
                    type="button"
                    buttonText={"Update"}
                    icon={
                      <RefreshIcon
                        className="block h-4 w-4 mr-2"
                        aria-hidden="true"
                      />
                    }
                    handleClick={() => updateProgram()}
                  />
                  <CustomButton
                    buttonStyle="bg-lightGray hover:text-white text-black"
                    type="button"
                    buttonText={"Cancel"}
                    icon={
                      <XCircleIcon
                        className="block h-4 w-4 mr-2"
                        aria-hidden="true"
                      />
                    }
                    handleClick={() => window.location.reload(true)}
                  />
                </div>
              </div>
            )}
            <div>
              <label className="text-lg font-bold">Type of program</label>
              <div className="capitalize">{program.type}</div>
            </div>

            {!updateFlag ? (
              <div>
                <label className="text-lg font-bold">Description</label>
                <div className="w-full overflow-y-auto h-min max-h-60  border-t border-b border-brown p-4">
                  {program.description}
                </div>
              </div>
            ) : (
              <div>
                <label className="text-lg font-bold">Description</label>
                <textarea
                  className="w-full overflow-y-auto h-60 max-h-80 border-t border-b border-brown p-4"
                  type="text"
                  value={editValues.description}
                  onChange={(e) =>
                    handleInputChange(e.target.value, "description")
                  }
                />
              </div>
            )}
            <div>
              <label className="text-lg font-bold">Program</label>
              <div className="border-t border-b border-brown p-4">
                {displayView()}
              </div>
            </div>
            {isSignedIn && (
              <ExportCSV
                csvData={program.exercises}
                fileName={program.programTitle}
              />
            )}
          </div>
        </div>

        {/* reviews */}
        <div className="flex flex-col items-start w-2/3 mt-1">
          {isSignedIn && (
            <Disclosure>
              <Disclosure.Button className="flex flex-start p-4 rounded-lg hover:text-white bg-whiteBrown hover:bg-brown">
                Review Program
              </Disclosure.Button>
              <Disclosure.Panel className="w-full">
                <ReviewForm
                  initialValues={{ experience: "none", opinion: "" }}
                  parentId={id}
                  editFlag={false}
                />
              </Disclosure.Panel>
            </Disclosure>
          )}
        </div>
        <div className="flex flex-col items-start w-2/3 mt-1">
          {program.reviews && program.reviews.length > 0 && (
            <div className="w-full">
              <div className="w-full flex justify-start text-2xl">Reviews</div>
              {program.reviews.map((review) => {
                return <ReviewCard key={review._id} review={review} />;
              })}
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => {
  let username = "";
  if (state.auth.username) {
    username = state.auth.username;
  }
  return {
    exercises: state.fetchedPrograms.exercises,
    fetchedPrograms: state.fetchedPrograms,
    editValues: state.fetchedPrograms.editProgram,
    username: username,
    isSignedIn: state.auth.isSignedIn,
  };
};

export default connect(mapStateToProps, {
  getProgram,
  loadExercises,
  loadEditValues,
  editProgram,
  deleteProgram,
})(Program);
