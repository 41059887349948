import React, { useState } from "react";
import { Field, reduxForm } from "redux-form";
import { Link } from "react-router-dom";

import CustomButton from "../components/CustomButton";
import ErrorMessage from "../components/ErrorMessage";
import { httpUploadImage } from "../apis/requestUser";
import TraningTypeDropdown from "./utils/TraningTypeDropdown";
import { wideRenderField } from "./utils/FieldComponents";

const validate = (values) => {
  const errors = {};
  if (!values.username) {
    errors.username = "Required";
  } else if (values.username.length > 15) {
    errors.username = "Username may not be more than 15 characters";
  } else if (values.username.length < 3) {
    errors.username = "Username must be at least 3 characters";
  }
  if (!values.email) {
    errors.email = "Required";
  } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
    errors.email = "Invalid email address";
  }
  if (!values.password) {
    errors.password = "Required";
  } else if (values.password.length < 8) {
    errors.password = "Password needs to be be at least 8 characters";
  }
  if (!values.passwordCopy) {
    errors.passwordCopy = "Required";
  }
  if (values.password !== values.passwordCopy) {
    errors.passwordCopy = "Passwords do not match";
  }
  return errors;
};

const RegisterForm = ({
  handleSubmit,
  submitting,
  onSubmitForm,
  errorText,
}) => {
  const [file, setFile] = useState("");

  const onSubmit = async (formValues) => {
    if (file !== "") {
      //create name for profile image
      const data = new FormData();
      const filename = Date.now() + file.name;
      data.append("name", filename);
      data.append("file", file);
      //add profile image name to form values
      formValues.profileImg = filename;
      //handle upload image to database
      await httpUploadImage(data);
    }
    //if no image selected set default profile image
    else {
      formValues.profileImg = "default.png";
    }
    onSubmitForm(formValues);
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="w-full">
      {errorText.length > 0 && <ErrorMessage text={errorText} />}

      <label className="text-xl">Username*</label>
      <Field
        name="username"
        type="text"
        component={wideRenderField}
        label="Type you username"
      />
      <label className="text-xl">Email*</label>
      <Field
        name="email"
        type="email"
        component={wideRenderField}
        label="Enter you email"
      />

      {file && (
        <img
          className="max-h-32 rounded"
          src={URL.createObjectURL(file)}
          alt=""
        />
      )}
      <label className="w-1/3 my-4 py-2 flex justify-center rounded bg-lightBrown border-2 border-brown flex hover:bg-brown">
        <input
          className="hidden"
          type="file"
          id="fileInput"
          accept="image/png, image/gif, image/jpeg"
          onChange={(e) => setFile(e.target.files[0])}
        />
        Select profile image
      </label>

      <label className="text-xl">Favorite traning form</label>
      <TraningTypeDropdown name="favoriteTraning" />

      <label className="text-xl">Password*</label>
      <Field
        name="password"
        type="password"
        component={wideRenderField}
        label="Enter a password"
      />
      <Field
        name="passwordCopy"
        type="password"
        component={wideRenderField}
        label="Enter password again"
      />

      <div className="my-4 flex gap-4 justify-center">
        <CustomButton
          buttonStyle="px-12 bg-lightBrown hover:bg-brown text-white"
          type="submit"
          disabled={submitting}
          buttonText={"Register"}
        />
      </div>

      <div>
        Already got an account?{" "}
        <Link className="text-blue" to="/login">
          Log in
        </Link>
      </div>
    </form>
  );
};

export default reduxForm({
  form: "registerform",
  validate,
})(RegisterForm);
