export const TraningTypes = [
  {
    value: "none",
    dropdownText: "None",
  },
  {
    value: "crossfit",
    dropdownText: "Crossfit",
  },
  {
    value: "bodybuilding",
    dropdownText: "Bodybuilding",
  },
  {
    value: "powerlifting",
    dropdownText: "Powerliftning",
  },
  {
    value: "cardio",
    dropdownText: "Cardio",
  },
  {
    value: "other",
    dropdownText: "Other",
  },
];
