import React from "react";
import { Navigate, Outlet } from "react-router-dom";
import { connect } from "react-redux";
import LoadingScreen from "../components/LoadingScreen";

const ProtectedRoute = ({ isSignedIn }) => {
  //waits for response from backend, if isSignedIn is false redirect to login
  if (isSignedIn !== null) {
    return isSignedIn ? <Outlet /> : <Navigate to="/login" />;
  }
  //while waiting for isSignedIn to be assigned false or true, show loading screen
  return <LoadingScreen />;
};
const mapStateToProps = (state) => {
  return {
    isSignedIn: state.auth.isSignedIn,
  };
};

export default connect(mapStateToProps)(ProtectedRoute);
