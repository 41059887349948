import React, { useState } from "react";
import { connect } from "react-redux";
import { reduxForm } from "redux-form";
import { Disclosure } from "@headlessui/react";
import { PencilAltIcon, TrashIcon } from "@heroicons/react/outline";
import { XCircleIcon } from "@heroicons/react/solid";

import ExerciseEditForm from "./ExerciseEditForm";
import ExerciseForm from "./ExerciseForm";
import Exercise from "./Exercise";
import { deleteWorkout } from "../../../actions/ProgramActions";

const Workout = ({
  date,
  exercises,
  deleteWorkout,
  deselectWorkout,
  previewFlag,
}) => {
  const [editFlag, setEditFlag] = useState(false);

  const editWorkout = () => {
    setEditFlag(!editFlag);
  };

  const onDeleteWorkout = () => {
    //call action creater to delete workout from redux store
    deleteWorkout(date);
    //callback to unselect workout
    deselectWorkout(date);
  };

  return (
    <Disclosure>
      <div className="w-full my-2">
        <Disclosure.Button className="rounded hover:text-white w-full">
          <div className="bg-lightBrown hover:bg-brown rounded p-2  text-center">
            <h1 className="flex justify-center text-2xl">Workout - {date}</h1>
          </div>
        </Disclosure.Button>
      </div>
      <Disclosure.Panel>
        {!previewFlag && (
          <ExerciseForm
            date={date}
            initialValues={{ [date]: { reps: 0, sets: 0, rest: 0 } }}
          />
        )}
        {exercises.length > 0 && (
          <div className="border-2 border-lightBrown px-8 py-4">
            <div className="flex justify-between">
              <div className="flex w-4/5 justify-between">
                <label className="w-full text-base font-bold">Order</label>
                <label className="w-full text-base font-bold">Exercise</label>
                <label className="w-full text-base font-bold">Reps</label>
                <label className="w-full  text-base font-bold">Sets</label>
                <label className="w-full text-base font-bold">
                  Rest between sets
                </label>
              </div>
              {!previewFlag && (
                <div className="flex ">
                  {!editFlag ? (
                    <div className="flex">
                      <PencilAltIcon
                        className="block w-8 text-lightBrown hover:text-brown"
                        aria-hidden="true"
                        onClick={() => editWorkout()}
                      />
                      <TrashIcon
                        className="block w-8 text-lightRed hover:text-red"
                        aria-hidden="true"
                        onClick={() => onDeleteWorkout()}
                      />
                    </div>
                  ) : (
                    <XCircleIcon
                      className="block w-8 text-lightRed hover:text-red"
                      aria-hidden="true"
                      onClick={() => editWorkout()}
                    />
                  )}
                </div>
              )}
            </div>

            {!editFlag
              ? exercises
                  .sort(
                    (exerciseA, exerciseB) =>
                      exerciseA.number - exerciseB.number
                  )
                  .map((exercise) => {
                    return (
                      <Exercise
                        key={exercise.id}
                        exerciseObject={exercise}
                        editFlag={editFlag}
                      />
                    );
                  })
              : exercises
                  .sort(
                    (exerciseA, exerciseB) =>
                      exerciseA.number - exerciseB.number
                  )
                  .map((exercise) => {
                    return (
                      <ExerciseEditForm
                        date={date}
                        key={exercise.id}
                        id={exercise.id}
                        initialValues={{ [exercise.id]: { ...exercise } }}
                        editFlag={editFlag}
                      />
                    );
                  })}
          </div>
        )}
      </Disclosure.Panel>
    </Disclosure>
  );
};

const mapStateToProps = (state, ownProps) => {
  let exercises = [];

  //get exercises related to workout during a certain date
  // eslint-disable-next-line no-unused-vars
  for (const [key, value] of Object.entries(state.program)) {
    if (value.date === ownProps.date) {
      exercises.push(value);
    }
  }
  return {
    formValues: state.form.programform,
    exercises: exercises,
  };
};

const WorkoutConnection = connect(mapStateToProps, { deleteWorkout })(Workout);

export default reduxForm({
  form: "programform",
  destroyOnUnmount: false,
})(WorkoutConnection);
