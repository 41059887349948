import React, { useEffect } from "react";
import CustomButton from "../components/CustomButton";
import { PlusCircleIcon } from "@heroicons/react/outline";
import { connect } from "react-redux";

import { getAllPrograms, searchPrograms } from "../actions/ProgramActions";
import ProgramCard from "../components/ProgramCard";
import SearchProgramForm from "../forms/programs/SearchProgramForm";

const Programs = ({ getAllPrograms, searchPrograms, programs }) => {
  useEffect(() => {
    getAllPrograms();
  }, [getAllPrograms]);

  //handle searches
  const onSearchPrograms = (formValues) => {
    searchPrograms(formValues);
  };

  return (
    <div className="flex flex-col h-max min-h-screen">
      <div className="w-full h-screen/2">
        <div className=" bg-cover bg-programs object-cover h-full w-full text-white flex flex-col items-center justify-center gap-4">
          <div className="bg-darkGreen rounded p-8 bg-opacity-60">
            <h1 className="text-6xl font-bold">Find programs that fits you</h1>
          </div>
        </div>
      </div>
      <div className="h-max w-full bg-normalGreen flex flex-col gap-8 p-8">
        <div className="w-full flex justify-end items-end gap-8 h-max">
          <SearchProgramForm onSubmit={onSearchPrograms} />
          <CustomButton
            buttonStyle="bg-brown w-1/6 h-1/2 mb-2 text-white"
            buttonText={"New Program"}
            icon={
              <PlusCircleIcon className="block h-6 w-6" aria-hidden="true" />
            }
            link={"/create-program"}
          />
        </div>
        <div className="w-full grid sm:grid-cols-1 lg:grid-cols-3 md:grid-cols-2 gap-8 text-center">
          {programs.length > 0 &&
            programs.map((program) => {
              return (
                <ProgramCard
                  key={program._id}
                  className="p-10 border-brown border rounded-2xl hover:text-white hover:bg-brown"
                  program={program}
                />
              );
            })}
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  let programs = {};
  if (state.fetchedPrograms && state.fetchedPrograms.programs) {
    programs = state.fetchedPrograms.programs;
  }
  return {
    programs: Object.values(programs),
  };
};

export default connect(mapStateToProps, { getAllPrograms, searchPrograms })(
  Programs
);
