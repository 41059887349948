import React, { useState } from "react";
import { connect } from "react-redux";
import RegisterForm from "../forms/RegisterForm";
import { registerUser } from "../actions/UserActions";

const Register = ({ registerUser }) => {
  const [errorText, setErrorText] = useState("");

  const onSubmitForm = async (formValues) => {
    const { passwordCopy, ...registerValues } = formValues;
    const response = await registerUser(registerValues);
    if (response !== undefined && response.length > 0) {
      setErrorText(response);
    }
  };

  return (
    <div className="bg-lightGreen h-max w-full flex justify-center pb-10">
      <div className="flex flex-col items-center w-3/5 mt-28 shadow-2xl rounded-xl h-fit p-8 bg-whiteBrown">
        <h2 className="text-4xl mb-8">Register New User</h2>
        {<RegisterForm onSubmitForm={onSubmitForm} errorText={errorText} />}
      </div>
    </div>
  );
};

export default connect(null, { registerUser })(Register);
