import React, { useState } from "react";
import { connect } from "react-redux";
import { XCircleIcon } from "@heroicons/react/solid";

import CustomButton from "./CustomButton";
import { httpDeleteFriend } from "../apis/requestFriend";
import LoadingImg from "./LoadingImg";

const Friend = ({
  searchedFor,
  friend,
  sentFriendRequests,
  friendRelationship,
  buttonOneText,
  buttonOneResponse,
  buttonTwoText,
  buttonTwoResponse,
  renderDeleteButton,
  userId,
}) => {
  const [imageLoaded, setImageLoaded] = useState(false);

  const removeFriend = async () => {
    await httpDeleteFriend(friend._id, userId);
    window.location.reload(true);
  };

  return (
    <div
      className={`flex justify-between p-2 items-center ${
        imageLoaded && "hover:bg-normalGreen"
      } ${searchedFor ? "border-b-brown border-b-4" : ""}`}
    >
      <a
        className="cursor-pointer"
        href={`/friends/account/${
          friend._id !== undefined ? friend._id : friend.id
        }`}
      >
        <div className="flex gap-4 items-center">
          <img
            className="h-12 w-12 rounded-full"
            src={process.env.REACT_APP_IMAGE_URL + friend.profileImg}
            alt=""
            onLoad={() => setImageLoaded(true)}
          />
          {!imageLoaded && <LoadingImg size={12} color={"whiteBrown"} />}
          {imageLoaded && <div className="text-lg">{friend.username}</div>}
        </div>
      </a>

      {/*check if delete button or accept/decline && show profile/add user buttons should be rendered */}
      {imageLoaded && (
        <div>
          {!renderDeleteButton ? (
            <div className="flex gap-2">
              {/* check if decline button or link account button should be rendered */}
              {buttonOneText === "Remove" ? (
                <CustomButton
                  type="button"
                  buttonStyle="bg-lightBrown hover:bg-brown text-white"
                  buttonText={buttonOneText}
                  handleClick={() => {
                    buttonOneResponse(friend._id);
                  }}
                />
              ) : (
                <div></div>
              )}
              {/* only render */}
              {!sentFriendRequests && !friendRelationship && (
                <CustomButton
                  type="submit"
                  buttonStyle="bg-lightBrown hover:bg-brown text-white"
                  buttonText={buttonTwoText}
                  handleClick={() => {
                    buttonTwoResponse(friend._id);
                  }}
                />
              )}
            </div>
          ) : (
            <XCircleIcon
              className="block w-8 text-lightRed hover:text-red"
              aria-hidden="true"
              onClick={() => removeFriend()}
            />
          )}
        </div>
      )}
    </div>
  );
};

const mapStateToProps = (state) => {
  return { userId: state.auth.id };
};

export default connect(mapStateToProps, {})(Friend);
