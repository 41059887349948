import React from "react";
import { reduxForm, Field } from "redux-form";

import { searchField } from "../utils/FieldComponents";

const SearchProgramForm = (props) => {
  const SearchTraningTypes = [
    {
      value: "all",
      dropdownText: "All/other",
    },
    {
      value: "crossfit",
      dropdownText: "Crossfit",
    },
    {
      value: "bodybuilding",
      dropdownText: "Bodybuilding",
    },
    {
      value: "powerlifting",
      dropdownText: "Powerliftning",
    },
    {
      value: "cardio",
      dropdownText: "Cardio",
    },
  ];

  const { handleSubmit } = props;

  return (
    <form onSubmit={handleSubmit} className="w-full">
      <div className="flex flex-start w-2/3 items-end">
        <div className="flex flex-col p-2">
          <label className="text-white text-md"> Traning type</label>
          <Field
            name="trainingType"
            component="select"
            className=" w-max h-10 rounded-lg p-2 mb-0.5"
          >
            {SearchTraningTypes.map((type) => {
              return (
                <option key={type.value} value={type.value}>
                  {type.dropdownText}
                </option>
              );
            })}
          </Field>
        </div>
        <Field
          name="textInput"
          component={searchField}
          type="text"
          text="Search"
          label="Search by username or program title"
        />
      </div>
    </form>
  );
};

export default reduxForm({
  form: "searchprogram",
})(SearchProgramForm);
