import format from "date-fns/format";
import getDay from "date-fns/getDay";
import parse from "date-fns/parse";
import startOfISOWeek from "date-fns/startOfISOWeek";
import { Calendar, dateFnsLocalizer } from "react-big-calendar";
import "react-big-calendar/lib/css/react-big-calendar.css";
import "react-datepicker/dist/react-datepicker.css";
import { connect } from "react-redux";

import { convertDate } from "../DateFunctions";

const locales = {
  "en-US": require("date-fns/locale/en-US"),
};
const localizer = dateFnsLocalizer({
  format,
  parse,
  startOfWeek: startOfISOWeek,
  getDay,
  locales,
});

function MyCalendar({ workoutCallback, events }) {
  const selectDate = (pressedDate) => {
    let date;
    if (pressedDate.slots) {
      date = convertDate(pressedDate.slots);
    } else if (pressedDate.start) {
      date = pressedDate.start;
    } else {
      date = convertDate(pressedDate);
    }
    workoutCallback(date);
  };

  //style events
  const eventPropGetter = () => {
    return {
      style: {
        backgroundColor: "#cb997e",
        width: "15px",
        height: "15px",
        borderRadius: "50%",
        border: "none",
        outline: "none",
      },
    };
  };

  return (
    <Calendar
      localizer={localizer}
      selectable={true}
      defaultView="month"
      eventPropGetter={eventPropGetter}
      onNavigate={selectDate}
      views={["month"]}
      events={events}
      startAccessor="start"
      onSelectEvent={selectDate}
      onSelectSlot={selectDate}
      endAccessor="end"
      style={{ height: 500 }}
    />
  );
}

const mapStateToProps = (state) => {
  const workoutDates = [];
  //get workout dates in redux store
  // eslint-disable-next-line no-unused-vars
  for (const [key, value] of Object.entries(state.program)) {
    if (!workoutDates.includes(value.date)) {
      workoutDates.push(value.date);
    }
  }

  //create event objects
  const events = [];
  workoutDates.forEach((date) =>
    events.push({
      allDay: true,
      start: date,
      end: date,
      width: "50px",
    })
  );
  return { events: events };
};

export default connect(mapStateToProps, {})(MyCalendar);
