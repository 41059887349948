import axios from "axios";

import { axiosInstance } from "./api";

//get request to rapid api endpoint to calculate bmi score
const httpCalculateBmi = async (weight, height) => {
  try {
    //set static age as api requires it but it does not affect the result
    const AGE = 30;
    return await axios.get("https://fitness-calculator.p.rapidapi.com/bmi", {
      params: { age: AGE, weight: weight, height: height },
      headers: {
        "X-RapidAPI-Host": "fitness-calculator.p.rapidapi.com",
        "X-RapidAPI-Key": process.env.REACT_APP_X_RAPIDAPI_KEY,
      },
    });
  } catch (e) {
    console.log(`Could not calculate BMI. Error: ${e}`);
    return null;
  }
};

//save bmi score to database
const httpSaveBmiScore = async (bmiScore) => {
  try {
    const response = await axiosInstance.post("/bmi", bmiScore, {
      withCredentials: true,
    });
    return response;
  } catch (err) {
    console.log(`BMI score could not be added. Error: ${err}`);
    return null;
  }
};

//get all bmi scores of user
const httpGetBmis = async (id) => {
  try {
    const response = await axiosInstance.get(`/bmi/${id}`, {
      withCredentials: true,
    });
    return response;
  } catch (err) {
    console.log(`BMI scores could not be fetched. Error: ${err}`);
    return null;
  }
};

//delete bmi
const httpDeleteBmi = async (id, bmiId) => {
  try {
    const response = await axiosInstance.delete(`/bmi/${id}/${bmiId}`, {
      withCredentials: true,
    });
    return response;
  } catch (err) {
    console.log(`BMI score could not be deleted. Error: ${err}`);
    return null;
  }
};

export { httpCalculateBmi, httpSaveBmiScore, httpGetBmis, httpDeleteBmi };
