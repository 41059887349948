export const ExperienceTypes = [
  {
    value: "none",
    dropdownText: "None - I haven't tried it",
  },
  {
    value: "beginner",
    dropdownText: "Beginner - I'm currently running it",
  },
  {
    value: "intermediate",
    dropdownText: "Intermediate - I have finished it",
  },
  {
    value: "advanced",
    dropdownText: "Advanced - I have finished it multiple times",
  },
];
