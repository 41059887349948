import _ from "lodash";
import { connect } from "react-redux";
import React, { useState, useEffect } from "react";

import FindFriendForm from "../forms/social/FindFriendForm";
import { httpGetUserByUsername, httpGetAllUsers } from "../apis/requestUser";
import {
  httpSendFriendRequest,
  httpGetSentFriendRequests,
  httpAcceptFriendRequest,
  httpDeclineFriendRequest,
} from "../apis/requestFriend";
import Friend from "../components/Friend";
import LoadingScreen from "../components/LoadingScreen";

const FindFriends = ({ isSignedIn, userId }) => {
  const [foundUser, setFoundUser] = useState({});
  const [allUsers, setAllUsers] = useState([]);
  const [sentFriendRequests, setSentFriendRequests] = useState([]);
  const [friendIds, setFriendIds] = useState([]);
  const [pendingFriendRequests, setPendingFriendRequests] = useState([]);

  useEffect(() => {
    if (isSignedIn) {
      const getAllUsers = async () => {
        const response = await httpGetAllUsers();
        response.data = response.data.filter((user) => user._id !== userId);
        setAllUsers(response.data);
      };
      getAllUsers();
      //get requests already sent by user
      const getFriendsAndRequests = async () => {
        const response = await httpGetSentFriendRequests(userId);
        if (response && response.status === 200) {
          setSentFriendRequests(response.data.waitingFriendResponse);
          setPendingFriendRequests(response.data.pendingFriendRequests);
          setFriendIds(response.data.friends);
        }
      };
      getFriendsAndRequests();
    }
  }, [isSignedIn, userId]);

  const searchUser = async (username) => {
    if (!_.isEmpty(username)) {
      const response = await httpGetUserByUsername(username.search);
      if (response.status === 200) {
        setFoundUser(response.data);
      }
    }
  };

  const onSendFriendRequest = (receiverId) => {
    setSentFriendRequests((sentFriendRequests) => [
      ...sentFriendRequests,
      receiverId,
    ]);
    httpSendFriendRequest(userId, receiverId);
  };

  const onAcceptFriendRequest = async (responseId) => {
    await httpAcceptFriendRequest(responseId, userId);
    window.location.reload(true);
  };

  const onDeclineFriendRequest = async (responseId) => {
    await httpDeclineFriendRequest(responseId, userId);
    window.location.reload(true);
  };

  if (!isSignedIn) {
    return <LoadingScreen />;
  }

  return (
    <div className="bg-lightGreen min-h-screen w-full flex-col items-center justify-center h-max">
      <div className="w-full h-screen-2/3">
        <div className=" bg-cover bg-traning-partner object-cover h-full w-full text-white flex flex-col items-center justify-center gap-4">
          <div className="bg-darkGreen rounded p-8 bg-opacity-60">
            <h1 className="text-6xl font-bold">Find a traning partner</h1>
          </div>
        </div>
      </div>
      <div className="w-full h-max bg-normalGreen pb-10">
        <div className=" flex flex-col justify-center items-center w-full ">
          <div className="w-3/4">
            <FindFriendForm onSubmit={searchUser} />
          </div>
          <div className="w-full flex flex-col bg-whiteBrown w-3/4 gap-4 p-1 rounded">
            {/*only display other users in this friend card format*/}
            {!_.isEmpty(foundUser) && foundUser.id !== userId && (
              <Friend
                key={foundUser.id}
                friend={foundUser}
                sentFriendRequests={sentFriendRequests.includes(foundUser.id)}
                friendRelationship={friendIds.includes(foundUser.id)}
                buttonTwoResponse={onSendFriendRequest}
                buttonTwoText="Add friend"
                searchedFor={true}
              />
            )}
            {/*if user search for themself display with this friend card format */}
            {foundUser.id === userId && (
              <div className="border-b-brown border-b-4">
                <Friend
                  key={foundUser._id}
                  friend={foundUser}
                  sentFriendRequests={false}
                  friendRelationship={true}
                  searchedFor={false}
                  renderDeleteButton={false}
                />
              </div>
            )}
            {allUsers &&
              allUsers.map((user) => {
                //if user has sent friend request render acccept and delete buttons
                if (pendingFriendRequests.includes(user._id)) {
                  return (
                    <Friend
                      key={user._id}
                      friend={user}
                      sentFriendRequests={false}
                      friendRelationship={friendIds.includes(user._id)}
                      buttonOneResponse={onDeclineFriendRequest}
                      buttonTwoResponse={onAcceptFriendRequest}
                      buttonOneText="Remove"
                      buttonTwoText="Accept"
                      searchedFor={false}
                      renderDeleteButton={false}
                    />
                  );
                } else {
                  return (
                    <Friend
                      key={user._id}
                      friend={user}
                      sentFriendRequests={sentFriendRequests.includes(user._id)}
                      friendRelationship={friendIds.includes(user._id)}
                      pendingFriendRequests={pendingFriendRequests.includes(
                        user._id
                      )}
                      buttonTwoResponse={onSendFriendRequest}
                      buttonOneText="Show profile"
                      buttonTwoText="Add friend"
                      searchedFor={false}
                      handelResponse={true}
                      renderDeleteButton={false}
                    />
                  );
                }
              })}
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  const userId = state.auth.isSignedIn ? state.auth.id : "";

  return {
    isSignedIn: state.auth.isSignedIn,
    userId: userId,
  };
};

export default connect(mapStateToProps, {})(FindFriends);
