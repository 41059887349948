export const ACTIVITY_LEVELS = [
  {
    value: "level_1",
    dropdownText: "Sedentary: little or no exercise",
  },
  {
    value: "level_2",
    dropdownText: "Exercise 1-3 times/week",
  },
  {
    value: "level_3",
    dropdownText: "Exercise 4-5 times/week",
  },
  {
    value: "level_4",
    dropdownText: "Daily exercise or intense exercise 3-4 times/week",
  },
  {
    value: "level_5",
    dropdownText: "Intense exercise 6-7 times/week",
  },
  {
    value: "level_6",
    dropdownText: "Very intense exercise daily, or physical job",
  },
];
