import _ from "lodash";

import {
  ADD_EXERCISE,
  DELETE_EXERCISE,
  DELETE_WORKOUT,
  CREATE_PROGRAM,
  LOAD_EXERCISES,
} from "../actions/types";

const programReducer = (state = {}, action) => {
  switch (action.type) {
    case ADD_EXERCISE:
      return { ...state, [action.payload.id]: action.payload };
    case DELETE_EXERCISE:
      return _.omit(state, action.payload);
    case CREATE_PROGRAM:
      return {};
    case DELETE_WORKOUT:
      const date = action.payload;
      const newState = {};
      for (const exerciseId in state) {
        if (state[exerciseId].date !== date) {
          newState[exerciseId] = state[exerciseId];
        }
      }
      return newState;
    case LOAD_EXERCISES:
      return { ...action.payload };
    default:
      return state;
  }
};

export default programReducer;
