import { connect } from "react-redux";
import { compose } from "redux";
import React, { useState } from "react";
import { Field, reduxForm } from "redux-form";
import ReactStars from "react-rating-stars-component";

import CustomButton from "../components/CustomButton";
import ErrorMessage from "../components/ErrorMessage";
import { renderTextArea } from "./utils/FieldComponents";
import TraningExperienceDropdown from "./utils/TraningExperienceDropdown";
import { httpPostReview, httpUpdateReview } from "../apis/requestProgram";

const ReviewForm = ({
  handleSubmit,
  parentId,
  username,
  previousRating,
  editFlag,
  reviewId,
}) => {
  const [stars, setStars] = useState(0);
  const [errorFlag, setErrorFlag] = useState("");

  const onUpdateReview = async (reviewValues) => {
    const response = await httpUpdateReview(reviewValues);
    if (response !== null && !response.data.error) {
      setErrorFlag(response.data.error);
      window.location.reload(true);
    }
  };

  const onPostReview = async (reviewValues) => {
    const response = await httpPostReview(reviewValues);
    if (response.data && !response.data.error) {
      window.location.reload(true);
    } else if (response.data && response.data.error) {
      setErrorFlag(response.data.error);
    }
    return response;
  };

  const onSubmit = async (formValues) => {
    if (username.length > 0) {
      if (!editFlag) {
        onPostReview({
          ...formValues,
          rating: stars,
          parentId: parentId,
          username: username,
        });
      } else {
        onUpdateReview({
          ...formValues,
          rating: stars,
          username: username,
          reviewId: reviewId,
        });
      }
    }
  };

  //handle star rating change
  const ratingChanged = (newRating) => {
    setStars(newRating);
  };

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      className="bg-whiteBrown rounded w-full mt-1 p-4 flex flex-col gap-4"
    >
      <div>
        <label className="text-xl">Rating</label>
        <ReactStars
          count={5}
          onChange={ratingChanged}
          size={24}
          value={previousRating}
          name="rating"
          isHalf={true}
          emptyIcon={<i className="far fa-star"></i>}
          halfIcon={<i className="fa fa-star-half-alt"></i>}
          fullIcon={<i className="fa fa-star"></i>}
          activeColor="#ffd700"
        />
      </div>
      <div className="flex flex-col">
        <label className="text-xl">Experience</label>
        <TraningExperienceDropdown />
      </div>
      <div>
        <label className="text-xl">What did you think of the program?</label>
        <Field
          name="opinion"
          component={renderTextArea}
          styling="w-full resize-none overflow-y-auto outline-none p-4 rounded-lg h-60"
          placeholder="Tell us what you think about the program..."
        />
      </div>
      <div className="flex w-full items-center flex-col">
        {errorFlag && <ErrorMessage text={errorFlag} />}
        <CustomButton
          buttonStyle="px-12 bg-lightBrown hover:bg-brown text-white"
          type="submit"
          buttonText={!editFlag ? "Post review" : "Update Review"}
        />
      </div>
    </form>
  );
};

const mapStateToProps = (state, ownProps) => {
  //set unique form name based on review id if review id exists
  const formName = ownProps.reviewId ? ownProps.reviewId : "review";
  //if user logged in set username else set username as empty string
  const username = state.auth.isSignedIn ? state.auth.username : "";
  return { username: username, form: formName };
};

export default compose(connect(mapStateToProps, {}), reduxForm({}))(ReviewForm);
