import { SELECT_DIET, LOAD_DIET } from "../actions/types";

const INITIAL_STATE = {
  name: "",
};

const dietReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SELECT_DIET:
      return { ...state, ...action.payload };
    case LOAD_DIET:
      return { ...state, userDiet: action.payload };
    default:
      return state;
  }
};

export default dietReducer;
