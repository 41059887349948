import React from "react";

const LoadingImg = ({ size, color }) => {
  return (
    <div className="flex justify-center items-center h-max">
      <div
        className={`spinner-border border-brown animate-spin inline-block w-${size} h-${size} border-2 rounded-full`}
        role="status"
      >
        <div className="rotate-90">
          <div className={`text-${color} bg-${color}`}>
            <span className="opacity-0">Loading...</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LoadingImg;
