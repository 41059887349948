import React from "react";
import { Field } from "redux-form";
import { TraningTypes } from "./TraningTypes";

const TraningTypeDropdown = ({ name }) => {
  return (
    <Field
      name={name}
      component="select"
      className="mb-8 py-2 w-full border-lightGray rounded-lg border-2"
    >
      {TraningTypes.map((type) => {
        return (
          <option key={type.value} value={type.value}>
            {type.dropdownText}
          </option>
        );
      })}
    </Field>
  );
};

export default TraningTypeDropdown;
