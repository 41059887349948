import React from "react";

const CustomButton = ({
  buttonText,
  icon,
  link,
  type,
  handleClick,
  disabled,
  buttonStyle,
}) => {
  return (
    <button
      onClick={handleClick}
      type={type}
      disabled={disabled}
      className={`rounded-lg px-4 py-2 ${buttonStyle}`}
    >
      {link ? (
        <a className="flex" href={link}>
          {icon}
          {buttonText}
        </a>
      ) : (
        <div className="flex items-center">
          {icon}
          {buttonText}
        </div>
      )}
    </button>
  );
};

export default CustomButton;
