import { axiosInstance } from "./api";

//send post request to login user
async function httpLogin(formValues) {
  try {
    const response = await axiosInstance.post(
      "/auth/login",
      { ...formValues },
      {
        withCredentials: true,
      }
    );
    return response;
  } catch (e) {
    console.log(`Cannot login. ${e}`);
    return null;
  }
}

//get current user session if there is one
async function httpGetUserSession() {
  try {
    const response = await axiosInstance.get("/auth/login", {
      withCredentials: "include",
    });
    return response;
  } catch (e) {
    console.log(`Authenticated as guest user.`);
  }
}

//add new user object to database
async function httpRegisterUser(formValues) {
  try {
    const response = await axiosInstance.post("/auth/register", formValues, {
      withCredentials: true,
    });
    return response;
  } catch (e) {
    console.log(`Couldn't create user due to: ${e}`);
    return null;
  }
}

//logout user which also removes cookies related to logged in user
async function httpLogout() {
  try {
    await axiosInstance.get("/auth/logout", {
      withCredentials: true,
    });
  } catch (e) {
    console.log(`Cannot logout.`);
    return null;
  }
}

export { httpLogin, httpGetUserSession, httpRegisterUser, httpLogout };
