import React, { useState } from "react";
import { reduxForm, reset } from "redux-form";
import { connect } from "react-redux";

import PageOneForm from "./PageOneForm";
import PageTwoForm from "./PageTwoForm";
import PageThreeForm from "./PageThreeForm";
import ProgramPreview from "./ProgramPreview";
import { createProgram } from "../../actions/ProgramActions";
import { httpUploadImage } from "../../apis/requestUser";

const ProgramForm = ({ formValues, program, createProgram, username }) => {
  const [page, setPage] = useState(1);
  const [file, setFile] = useState("");
  const [errorFlag, setErrorFlag] = useState("");

  const nextPage = () => {
    setPage(page + 1);
  };

  const programImageCallback = (img) => {
    setFile(img);
  };

  const previousPage = () => {
    setPage(page - 1);
  };

  const onSumbitForm = async () => {
    const programData = {};
    programData.formValues = formValues;
    programData.workouts = program;
    programData.file = "";
    //upload program file
    let data = "";
    if (file !== "") {
      //create name for profile image
      data = new FormData();
      const filename = Date.now() + file.name;
      data.append("name", filename);
      data.append("file", file);
      //add profile image name to form values
      programData.file = filename;
      //handle upload image to database
    }
    let response = {};
    if (username !== "") {
      programData.author = username;
      response = await createProgram(programData);
    } else {
      setErrorFlag("You are not logged in. Please log in to create a program.");
    }
    //upload image if program was posted successfully and there is an image to add
    if (response.data && data !== "") {
      await httpUploadImage(data);
    }
  };

  return (
    <div className="flex flex-col w-full mt-12 items-center">
      <h1 className="text-2xl"> Create a traning program </h1>
      <div className="text-sm">Step {page}/4</div>
      <div className="w-full flex justify-center mt-2 h-max mb-8 min-h-screen">
        <div className="w-2/3 bg-whiteBrown p-4 rounded h-max">
          {page === 1 && (
            <PageOneForm
              onSubmit={nextPage}
              initialValues={{ timePeriod: "day" }}
            />
          )}
          {page === 2 && (
            <PageTwoForm
              previousPage={previousPage}
              onSubmit={nextPage}
              previewFlag={false}
            />
          )}
          {page === 3 && (
            <PageThreeForm
              previousPage={previousPage}
              onSubmit={nextPage}
              file={file}
              programImageCallback={programImageCallback}
            />
          )}
          {page === 4 && (
            <ProgramPreview
              previousPage={previousPage}
              onSumbitForm={onSumbitForm}
              file={file}
              errorFlag={errorFlag}
            />
          )}
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  const username = state.auth.isSignedIn ? state.auth.username : "";
  let formValues = {};
  if (state.form.programform && state.form.programform.values) {
    formValues = state.form.programform.values;
  }
  return {
    formValues: formValues,
    program: state.program,
    username: username,
  };
};

//clear fields values after successful submit
const afterSubmit = (result, dispatch) => {
  dispatch(reset("programform"));
};

const ProgramFormConnection = connect(mapStateToProps, { createProgram })(
  ProgramForm
);

export default reduxForm({
  form: "programform",
  onSubmitSuccess: afterSubmit,
})(ProgramFormConnection);
