import {
  SIGN_IN,
  SIGN_OUT,
  CREATE_USER,
  UPDATE_USER,
  DELETE_USER,
} from "./types";
import {
  httpLogin,
  httpGetUserSession,
  httpLogout,
  httpRegisterUser,
} from "../apis/requestAuth";
import {
  httpUpdateUser,
  httpUploadImage,
  httpDeleteImage,
  httpDeleteUser,
} from "../apis/requestUser";
import history from "../routes/history";

const login = (formValues) => async (dispatch) => {
  const response = await httpLogin(formValues);
  if (response) {
    dispatch({ type: SIGN_IN, payload: response.data });
    history.push("/");
  } else {
    return null;
  }
};

//get current session
const getUserSession = () => async (dispatch) => {
  const response = await httpGetUserSession();
  if (response) {
    //set isSignedIn to true
    dispatch({ type: SIGN_IN, payload: response.data });
  } else {
    //set isSignedIn to false
    dispatch({ type: SIGN_OUT });
  }
};

//create new user and redirect to login page
const registerUser = (formValues) => async (dispatch) => {
  const response = await httpRegisterUser(formValues);
  if (response.data.error) {
    return response.data.error;
  }
  dispatch({ type: CREATE_USER, payload: response.data });
  history.push("/login");
};

//update user data
const updateUser =
  (formValues, user, newImage, imageData) => async (dispatch) => {
    const response = await httpUpdateUser(formValues, user.id);
    //if status code not 200
    if (response.data.error) {
      return response.data;
    } else {
      //only upload and delete image if update was sucessful
      if (newImage !== "") {
        await httpUploadImage(imageData);
        //delete old image
        if (user.profileImg !== "") {
          await httpDeleteImage(user.profileImg);
        }
      }
      //update redux store values
      dispatch({ type: UPDATE_USER, payload: response.data });
      //reload account page removing user form inputs
      window.location.reload(true);
      return response;
    }
  };

//delete user and redirect to home page
const deleteUser = (userId) => async (dispatch) => {
  await httpDeleteUser(userId);
  dispatch({ type: DELETE_USER });
  history.push("/");
};

//logout removes cookies and redirects to home page
const logout = () => async (dispatch) => {
  await httpLogout();
  dispatch({ type: SIGN_OUT });
  history.push("/");
};

export { login, getUserSession, registerUser, logout, updateUser, deleteUser };
