import { connect } from "react-redux";
import React, { useState, useEffect } from "react";

import { httpGetBmis, httpDeleteBmi } from "../apis/requestBmi";
import { getUserDiet } from "../actions/DietActions";
import BmiCard from "../components/BmiCard";
import CustomButton from "../components/CustomButton";
import Macros from "../components/Macros";
import LoadingScreen from "../components/LoadingScreen";

function Health({ id, getUserDiet, userDiet }) {
  const [bmiScores, setBmiScores] = useState([]);

  useEffect(() => {
    const loadBmis = async () => {
      if (id.length > 0) {
        //get program and exercises related to program
        const response = await httpGetBmis(id);
        //set program if empty
        setBmiScores(response.data);
      }
    };
    loadBmis();
  }, [id]);

  //get user diet
  useEffect(() => {
    if (id) {
      getUserDiet(id);
    }
  }, [id, getUserDiet]);

  const deleteBmiCallback = async (bmiId) => {
    const response = await httpDeleteBmi(id, bmiId);
    if (response.status === 200) {
      window.location.reload(true);
    }
  };

  if (!id) {
    return <LoadingScreen />;
  }

  return (
    <div className="h-max min-h-screen mt-8 w-full flex justify-center">
      <div className="w-2/3 p-4">
        <div className="flex justify-center p-2">
          <div className="flex flex-col items-center ">
            <label className="text-2xl font-bold">Your current diet:</label>
            {userDiet ? (
              <div className="w-full border-2 p-2 border-normalGreen bg-darkGreen hover:text-white rounded">
                <span className={"text-white font-bold"}>
                  {userDiet.name}, {Math.round(userDiet.calories)} kcal
                </span>
                <Macros
                  selected={true}
                  protein={userDiet.protein}
                  carbs={userDiet.carbs}
                  fat={userDiet.fat}
                />
              </div>
            ) : (
              <div className="text-2xl w-full items-center flex flex-col gap-2 my-4">
                <CustomButton
                  buttonStyle="px-12 bg-lightBrown hover:bg-brown text-white"
                  type="submit"
                  buttonText={"Create diet plan"}
                  link={"/nutrition"}
                />
              </div>
            )}
          </div>
        </div>
        <div className="text-2xl flex w-full justify-center font-bold">
          Your BMI data:{" "}
        </div>
        <div className="text-md mb-8 flex w-full justify-center">
          Healthy range is: 18-25
        </div>
        {bmiScores.length > 0 ? (
          <div className="flex flex-col gap-2">
            {bmiScores.map((bmiScore) => {
              return (
                <BmiCard
                  key={bmiScore._id}
                  bmiScore={bmiScore}
                  deleteBmiCallback={deleteBmiCallback}
                />
              );
            })}
          </div>
        ) : (
          <div className="text-2xl w-full items-center flex flex-col gap-2">
            <CustomButton
              buttonStyle="px-12 bg-lightBrown hover:bg-brown text-white"
              type="submit"
              buttonText={"Create BMI data"}
              link={"/bmi-calculator"}
            />
          </div>
        )}
      </div>
    </div>
  );
}

const mapStateToProps = (state) => {
  const id = state.auth.isSignedIn ? state.auth.id : "";
  const userDiet =
    state.auth.isSignedIn && state.diet.userDiet ? state.diet.userDiet : "";
  return {
    id: id,
    userDiet: userDiet,
  };
};

export default connect(mapStateToProps, { getUserDiet })(Health);
