import { io } from "socket.io-client";
import { connect } from "react-redux";
import React, { useEffect, useRef, useState } from "react";
import _ from "lodash";

import Chatbox from "../components/Chatbox";
import ChatFriendList from "../components/ChatFriendList";
import {
  getConversations,
  addSocketMessage,
  updateOnlineUsers,
} from "../actions/ChatActions";
import LoadingScreen from "../components/LoadingScreen";
import CustomButton from "../components/CustomButton";

const Chat = ({
  isSignedIn,
  userId,
  getConversations,
  addSocketMessage,
  updateOnlineUsers,
  friendSelected,
}) => {
  //current chat
  const [socketMessage, setSocketMessage] = useState({});

  //reference to socket
  const socket = useRef();

  //handle sockets, add user to online users and get messages
  useEffect(() => {
    socket.current = io(process.env.REACT_APP_SOCKET_URL);
    //add user to online users
    socket?.current.emit("addUser", userId);
    //keep track of online users
    socket?.current.on("allUsers", (allUsers) => {
      const userIds = allUsers.map((user) => user.userId);
      updateOnlineUsers(userIds);
    });
    //handle receiving messages
    socket?.current.on("getMessage", (message) => {
      setSocketMessage(message);
    });
  }, [userId, updateOnlineUsers]);

  //handle adding socket messages to redux store
  useEffect(() => {
    //check if user has conversation opened
    if (
      !_.isEmpty(socketMessage) &&
      friendSelected.conversationId === socketMessage.conversationId
    ) {
      addSocketMessage(socketMessage);
    }
  }, [socketMessage, addSocketMessage, friendSelected]);

  //get all current conversations of user
  useEffect(() => {
    if (isSignedIn) {
      const loadConversations = async () => {
        await getConversations(userId);
      };
      loadConversations();
    }
  }, [userId, isSignedIn, getConversations]);

  if (!isSignedIn) {
    return <LoadingScreen />;
  }

  return (
    <div className="min-h-screen h-max w-full">
      <div className="flex mt-8 gap-2">
        <div className="w-2/6 flex flex-col gap-2 items-center">
          <ChatFriendList />
          <CustomButton
            buttonStyle="p-8 px-16 bg-lightBrown hover:bg-brown text-white text-xl w-max"
            type="submit"
            buttonText={"Find more friends"}
            link={"/friends"}
          />
        </div>
        <div className="w-4/6">
          <Chatbox socket={socket} />
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  const userId = state.auth.isSignedIn ? state.auth.id : "";
  const friendSelected =
    state.chat && state.chat.friendSelected ? state.chat.friendSelected : {};

  return {
    isSignedIn: state.auth.isSignedIn,
    userId: userId,
    socket: state.socket,
    friendSelected: friendSelected,
  };
};

export default connect(mapStateToProps, {
  getConversations,
  addSocketMessage,
  updateOnlineUsers,
})(Chat);
