import {
  SET_CONVERSATIONS,
  SELECT_FRIEND,
  CONVERSATION_MESSAGES,
  ADD_MESSAGE,
  ADD_SOCKET_MESSAGE,
  ONLINE_USERS,
} from "../actions/types";

const chatReducer = (state = {}, action) => {
  switch (action.type) {
    case SET_CONVERSATIONS:
      return { ...state, conversations: action.payload };
    case SELECT_FRIEND:
      return { ...state, friendSelected: action.payload };
    case CONVERSATION_MESSAGES:
      return { ...state, messages: action.payload };
    case ADD_MESSAGE:
    case ADD_SOCKET_MESSAGE:
      return { ...state, messages: [...state.messages, action.payload] };
    case ONLINE_USERS:
      return { ...state, online: action.payload };
    default:
      return state;
  }
};

export default chatReducer;
