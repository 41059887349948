import React, { useState } from "react";
import { reduxForm } from "redux-form";
import { connect } from "react-redux";
import moment from "moment";

import CustomButton from "../../../../components/CustomButton";
import WeekComponent from "./WeekComponent";
import Workout from "../Workout";
import { deleteWorkout } from "../../../../actions/ProgramActions";
import { getDatesOfWeek } from "../DateFunctions";

const Week = ({ workoutWeeks, workoutDates, deleteWorkout, previewFlag }) => {
  //if workouts exist set intial weeks to weeks containing workouts otherwise default to current week
  const initialWeeks =
    workoutWeeks.length > 0
      ? workoutWeeks.sort()
      : [moment(new Date()).isoWeek()];

  const [weeks, setWeeks] = useState(initialWeeks);
  const [workoutDate, setWorkoutDate] = useState("");

  const workoutCallback = (date) => {
    setWorkoutDate(date);
  };

  const deleteWeek = () => {
    const lastWeek = weeks.at(-1);
    //get dates of last week
    const datesOfLastWeek = getDatesOfWeek(lastWeek);
    //remove any workouts related to the dates of the week
    datesOfLastWeek.forEach((date) => deleteWorkout(date));
    //update state of week removing the last week
    setWeeks(weeks.filter((item) => item !== weeks.at(-1)));
  };

  const deselectWorkout = (selectedWorkout) => {
    setWorkoutDate("");
    //get week of deselected workout
    const workoutWeek = moment(selectedWorkout).isoWeek();
    //check if any other workout is in the week
    let workoutsInWeek = false;
    workoutDates.forEach((date) => {
      if (workoutWeek === moment(date).isoWeek() && selectedWorkout !== date) {
        workoutsInWeek = true;
      }
    });
    //remove workoutweek if no workouts left in week
    if (!workoutsInWeek) {
      const newWeeks = weeks.filter((week) => week !== workoutWeek);
      //if no week left default to current week
      if (newWeeks.length === 0) {
        newWeeks.push(moment(new Date()).isoWeek());
      }
      setWeeks(newWeeks);
    }
  };

  return (
    <div className="flex flex-col mb-8 gap-2">
      {!previewFlag && (
        <div className="w-full flex gap-4 justify-end my-2">
          <CustomButton
            buttonStyle="bg-lightGreen hover:bg-normalGreen hover:text-white text-black"
            type="button"
            buttonText={"Add week"}
            handleClick={() => setWeeks([...weeks, parseInt(weeks.at(-1)) + 1])}
          />
          <CustomButton
            buttonStyle="bg-lightRed hover:bg-red hover:bg-lightGreen hover:text-white text-black"
            type="button"
            buttonText={"Remove latest week"}
            handleClick={() => {
              weeks.length !== 1 && deleteWeek();
            }}
          />
        </div>
      )}
      <div>
        {weeks.map((numberOfWeek) => {
          return (
            <WeekComponent
              numberOfWeek={numberOfWeek}
              key={numberOfWeek}
              workoutCallback={workoutCallback}
              workoutDates={workoutDates}
            />
          );
        })}
      </div>
      {workoutDate && (
        <Workout
          date={workoutDate}
          key={workoutDate}
          deselectWorkout={deselectWorkout}
          previewFlag={previewFlag}
        />
      )}
    </div>
  );
};

const mapStateToProps = (state) => {
  const workoutWeeks = [];
  const workoutDates = [];
  //get weeks with workouts in them
  // eslint-disable-next-line no-unused-vars
  for (const [key, value] of Object.entries(state.program)) {
    if (!workoutDates.includes(value.date)) {
      workoutDates.push(value.date);
    }
    if (!workoutWeeks.includes(moment(value.date).isoWeek())) {
      workoutWeeks.push(moment(value.date).isoWeek());
    }
  }
  return { workoutWeeks: workoutWeeks, workoutDates: workoutDates };
};

const WeekTemplateConnection = connect(mapStateToProps, { deleteWorkout })(
  Week
);

export default reduxForm({
  form: "programform",
  destroyOnUnmount: false,
})(WeekTemplateConnection);
