import _ from "lodash";
import { SELECT_DIET, LOAD_DIET } from "./types";
import { httpGetDiet } from "../apis/requestNutrition";

//store selected diet in redux store
const selectDiet = (diet) => async (dispatch) => {
  dispatch({ type: SELECT_DIET, payload: diet });
};

const getUserDiet = (userId) => async (dispatch) => {
  const response = await httpGetDiet(userId);
  //check if diet exists before dispatching
  if (response && response.data && !_.isEmpty(response.data)) {
    dispatch({ type: LOAD_DIET, payload: response.data });
  }
};

export { selectDiet, getUserDiet };
