import { axiosInstance } from "./api";

//save profile image
const httpUploadImage = async (imgData) => {
  try {
    await axiosInstance.post("/image/upload", imgData, {
      withCredentials: true,
    });
  } catch (err) {
    console.log(`Profile image could not be uploaded.`);
  }
};

//delete profile image
const httpDeleteImage = async (imageName) => {
  try {
    await axiosInstance.delete(`/image/${imageName}`, {
      withCredentials: true,
    });
  } catch (err) {
    console.log(`File could not be deleted.`);
  }
};

//delete user and everything realted to user
const httpDeleteUser = async (userId) => {
  try {
    await axiosInstance.delete(`/user/${userId}`, {
      withCredentials: true,
    });
  } catch (err) {
    console.log(`User could not be deleted.`);
  }
};

//update user with new user values
const httpUpdateUser = async (formValues, userId) => {
  try {
    const response = await axiosInstance.put(
      `/user/update/${userId}`,
      { formValues },
      {
        withCredentials: true,
      }
    );
    return response;
  } catch (err) {
    console.log(`User could not be uploaded.`);
  }
};

//get username and profile image of user
const httpGetUser = async (userId) => {
  try {
    const response = await axiosInstance.get(`/user/${userId}`, {
      withCredentials: true,
    });
    return response;
  } catch (err) {
    console.log(`User could not be fetched.`);
  }
};

//get detailed user data from another user
const httpGetDetailedUser = async (otherUserId) => {
  try {
    const response = await axiosInstance.get(`/user/detailed/${otherUserId}`, {
      withCredentials: true,
    });
    return response;
  } catch (err) {
    console.log(`User could not be fetched.`);
  }
};

//get username and profile image of user
const httpGetUserByUsername = async (username) => {
  try {
    const response = await axiosInstance.get(`/user/username/${username}`, {
      withCredentials: true,
    });
    return response;
  } catch (err) {
    console.log(`User could not be fetched.`);
  }
};

//get username and profile image of user
const httpGetAllUsers = async () => {
  try {
    const response = await axiosInstance.get(`/user`, {
      withCredentials: true,
    });
    return response;
  } catch (err) {
    console.log(`User could not be fetched.`);
  }
};

export {
  httpUploadImage,
  httpDeleteImage,
  httpDeleteUser,
  httpUpdateUser,
  httpGetUser,
  httpGetDetailedUser,
  httpGetUserByUsername,
  httpGetAllUsers,
};
