import React, { useState } from "react";
import { connect } from "react-redux";
import _ from "lodash";

import { httpCalculateBmi, httpSaveBmiScore } from "../apis/requestBmi";
import BmiCalculatorForm from "../forms/apis/BmiCalculatorForm";
import CustomButton from "../components/CustomButton";
import { convertDate } from "../forms/programs/pageTwoComponents/DateFunctions";
import ErrorMessage from "../components/ErrorMessage";
import LoadingScreen from "../components/LoadingScreen";

const BmiCalculator = ({ isSignedIn, username }) => {
  const [bmiData, setBmiData] = useState({});
  const [errorFlag, setErrorFlag] = useState("");
  const CURRENT_DATE = convertDate(new Date());

  const onSubmitForm = async (formValues) => {
    const response = await httpCalculateBmi(
      formValues.weight,
      formValues.height
    );
    if (response && response.data.status_code === 200) {
      response.data.data.height = formValues.height;
      response.data.data.weight = formValues.weight;
      setBmiData(response.data.data);
    }
  };

  if (!isSignedIn) {
    return <LoadingScreen />;
  }

  //save bmi data to user account
  const saveBmiScore = async () => {
    if (!isSignedIn) {
      setErrorFlag("Log in to save BMI score.");
    } else if (!_.isEmpty(bmiData)) {
      //exclude healthy BMI range
      const { healthy_bmi_range, ...bmiScore } = bmiData;
      //add current date from when data was created
      bmiScore.date = CURRENT_DATE;
      //add username to bmi
      bmiScore.username = username;
      bmiScore.height = bmiData.height;
      bmiScore.weight = bmiData.weight;
      const response = await httpSaveBmiScore(bmiScore);
      if (response.data && response.data.error) {
        setErrorFlag(response.data.error);
      } else {
        window.location.reload(true);
      }
    }
  };

  return (
    <div className="bg-lightGreen h-max w-full flex justify-center pb-10 min-h-screen mt-8">
      <div className="flex flex-col items-center w-2/3 gap-8">
        <h1 className="text-white text-4xl">BMI Calculator</h1>
        <div className="border-b-darkGreen border-b-4 p-2 text-white">
          Body Mass Index (BMI) is a person’s weight in kilograms (or pounds)
          divided by the square of height in meters (or feet). A high BMI can
          indicate high body fatness. BMI screens for weight categories that may
          lead to health problems, but it does not diagnose the body fatness or
          health of an individual.
          <div className="italic">
            <span className="font-bold">Important:</span> BMI incorporates
            weight and height, but it does not take age or sex into account.
          </div>
        </div>
        <div className="flex w-full">
          <div className="justify-center flex w-full flex-1 p-4 border-r-darkGreen border-r-2">
            <BmiCalculatorForm onSubmitForm={onSubmitForm} />
          </div>
          <div className="p-4 flex-1 flex flex-col gap-4">
            <h1 className="text-2xl border-b-normalGreen border-b-2 w-full">
              Results
            </h1>
            {!_.isEmpty(bmiData) && (
              <div className="flex flex-col gap-8">
                <div className="flex flex-col gap-2 text-lg">
                  <div>
                    <span className="font-bold">BMI:</span> {bmiData.bmi}
                  </div>
                  <div>
                    <span className="font-bold">Health status:</span>{" "}
                    {bmiData.health}
                  </div>
                  <div>
                    <span className="font-bold">Healthy BMI range:</span>{" "}
                    {bmiData.healthy_bmi_range}
                  </div>
                  <div>
                    <span className="font-bold">Current date:</span>{" "}
                    {CURRENT_DATE}
                  </div>
                </div>
                <CustomButton
                  buttonStyle="bg-normalGreen w-max hover:bg-darkGreen hover:text-white text-black"
                  type="button"
                  buttonText={"Save BMI score"}
                  handleClick={() => saveBmiScore()}
                />
                {errorFlag && <ErrorMessage text={errorFlag} />}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  const username = state.auth.isSignedIn ? state.auth.username : "";

  return {
    isSignedIn: state.auth.isSignedIn,
    username: username,
  };
};

export default connect(mapStateToProps, {})(BmiCalculator);
