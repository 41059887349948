import React, { useState } from "react";
import { reduxForm } from "redux-form";

import Calendar from "./Calendar";
import Workout from "../Workout";

const Month = ({ previewFlag }) => {
  const [workoutDate, setWorkoutDate] = useState("");

  const workoutCallback = (date) => {
    setWorkoutDate(date);
  };

  const deselectWorkout = () => {
    setWorkoutDate("");
  };

  return (
    <div className="flex flex-col mb-2 gap-2 justify-center w-full text-center">
      <h1 className="text-2xl">Calendar</h1>
      <Calendar workoutCallback={workoutCallback} />

      {workoutDate && (
        <Workout
          date={workoutDate}
          key={workoutDate}
          deselectWorkout={deselectWorkout}
          previewFlag={previewFlag}
        />
      )}
    </div>
  );
};

export default reduxForm({
  form: "programform",
  destroyOnUnmount: false,
})(Month);
