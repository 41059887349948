import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Disclosure } from "@headlessui/react";
import _ from "lodash";

import { httpGetUser } from "../apis/requestUser";
import Friend from "../components/Friend";
import {
  httpAcceptFriendRequest,
  httpDeclineFriendRequest,
  httpGetFriends,
} from "../apis/requestFriend";
import LoadingScreen from "../components/LoadingScreen";

function Friends({ friendRequests, userId }) {
  const [pendingFriends, setPendingFriends] = useState([]);
  const [friends, setFriends] = useState([]);

  useEffect(() => {
    //get users that has sent friend requests
    const getAllPendingFriends = async () => {
      for (const friendRequest of friendRequests) {
        const response = await httpGetUser(friendRequest);
        //if response data is empty that probably means the user that sent
        //the friend request has been deleted, therefore automatically decline
        if (_.isEmpty(response.data)) {
          await httpDeclineFriendRequest(friendRequest, userId);
          //check that pending friend id does not already exist in pendingFriends
        } else if (
          !(
            pendingFriends.filter(
              (pendingFriend) => pendingFriend._id === friendRequest
            ).length > 0
          )
        ) {
          setPendingFriends((pendingFriends) => [
            ...pendingFriends,
            response.data,
          ]);
        }
      }
    };
    if (friendRequests && friendRequests.length > 0) {
      getAllPendingFriends();
    }
  }, [friendRequests, pendingFriends, userId]);

  //get all user friends
  useEffect(() => {
    const getUserFriends = async () => {
      //get friend ids
      const response = await httpGetFriends(userId);
      const friendIds = response.data.friends;
      //get friend profiles
      for (const friendId of friendIds) {
        const response = await httpGetUser(friendId);
        setFriends((friends) => [...friends, response.data]);
      }
    };
    if (userId && userId.length > 0) {
      getUserFriends();
    }
  }, [userId]);

  const onAcceptFriendRequest = async (responseId) => {
    await httpAcceptFriendRequest(responseId, userId);
    window.location.reload(true);
  };

  const onDeclineFriendRequest = async (responseId) => {
    await httpDeclineFriendRequest(responseId, userId);
    window.location.reload(true);
  };

  if (!userId) {
    return <LoadingScreen />;
  }

  return (
    <div className="bg-lightGreen min-h-screen w-full flex-col h-max pb-10 mt-8 flex gap-4">
      <Disclosure>
        <Disclosure.Button className="flex flex-start rounded gap-0">
          <div className="group bg-whiteBrown hover:bg-lightBrown rounded p-2 px-8 hover:text-white ">
            <div className="flex gap-1">
              <div> Friends requests </div>
              <div className="font-bold flex justify-center items-center w-6 h-6 text-xs rounded-full relative bg-lightRed left-0.5 bottom-1 group-hover:bg-red z-1">
                {friendRequests && pendingFriends && pendingFriends.length}
              </div>
            </div>
          </div>
        </Disclosure.Button>
        <Disclosure.Panel>
          {pendingFriends && pendingFriends.length > 0 ? (
            <div className="w-full bg-whiteBrown rounded p-1">
              {pendingFriends.map((user) => {
                return (
                  <Friend
                    key={user._id}
                    friend={user}
                    sentFriendRequests={false}
                    friendRelationship={false}
                    buttonOneResponse={onDeclineFriendRequest}
                    buttonTwoResponse={onAcceptFriendRequest}
                    buttonOneText="Remove"
                    buttonTwoText="Accept"
                    searchedFor={false}
                    renderDeleteButton={false}
                  />
                );
              })}
            </div>
          ) : (
            <div className="px-10">No friend requests</div>
          )}
        </Disclosure.Panel>
      </Disclosure>
      <div className="w-full">
        <h1 className="text-2xl border-b-brown border-b-2 mb-2">Friends</h1>
        {friends && friends.length > 0 ? (
          <div className="w-full bg-whiteBrown rounded p-1">
            {friends.map((user) => {
              return (
                <Friend
                  key={user._id}
                  friend={user}
                  sentFriendRequests={[]}
                  renderDeleteButton={true}
                />
              );
            })}
          </div>
        ) : (
          <div>0 friends</div>
        )}
      </div>
    </div>
  );
}

const mapStateToProps = (state) => {
  const friendRequests = state.auth.friendRequests;
  const userId = state.auth.id;
  return {
    friendRequests,
    userId,
  };
};

export default connect(mapStateToProps, {})(Friends);
