import React, { useState } from "react";
import { connect } from "react-redux";

import { logout, updateUser, deleteUser } from "../actions/UserActions";
import AccountForm from "../forms/AccountForm";
import CustomButton from "../components/CustomButton";
import LoadingScreen from "../components/LoadingScreen";

const Account = ({ user, logout, updateUser, deleteUser }) => {
  const [errorFlag, setErrorFlag] = useState("");

  const updateUserCallback = async (formValues, newImage, imageData) => {
    const { passwordCopy, ...newValues } = formValues;
    const response = await updateUser(newValues, user, newImage, imageData);
    //handle update errors
    if (response.error) {
      setErrorFlag(response.error);
    } else {
      //reset error flag
      setErrorFlag("");
    }
  };

  if (!user.isSignedIn) {
    return <LoadingScreen />;
  }

  return (
    <div className="bg-lightGreen min-h-screen h-max w-full flex justify-center h-max pb-10">
      {user.isSignedIn && (
        <div className="w-4/5 mt-8 shadow-4xl rounded-xl bg-whiteBrown border-2 border-normalGreen">
          <AccountForm
            user={user}
            errorFlag={errorFlag}
            updateUserCallback={updateUserCallback}
            initialValues={{
              username: user.username,
              email: user.email,
              favoriteTraning: user.favoriteTraning,
            }}
          />
          {/* buttons */}
          <div className="flex flex-col items-center p-2">
            <div className="flex gap-4 w-2/3 ">
              <CustomButton
                buttonStyle="bg-whiteBrown border-2 border-brown hover:bg-brown hover:text-white text-black"
                type="button"
                buttonText={"Logout"}
                handleClick={() => logout()}
              />
              <CustomButton
                buttonStyle="bg-lightRed hover:bg-red hover:text-white text-black"
                type="button"
                buttonText={"Delete Profile"}
                handleClick={() => deleteUser(user.id)}
              />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.auth,
  };
};

export default connect(mapStateToProps, {
  logout,
  updateUser,
  deleteUser,
})(Account);
