import { axiosInstance } from "./api";

//get all bmi scores of user
const httpGetConversations = async (userId) => {
  try {
    const response = await axiosInstance.get(`/conversations/${userId}`, {
      withCredentials: true,
    });
    return response;
  } catch (err) {
    console.log(`Conversations could not be fetched. Error: ${err}`);
    return null;
  }
};

//get messages for conversation
const httpGetConversationMessages = async (conversationId) => {
  try {
    const response = await axiosInstance.get(`/messages/${conversationId}`, {
      withCredentials: true,
    });
    return response;
  } catch (err) {
    console.log(`Messages could not be fetched. Error: ${err}`);
    return null;
  }
};

//post message for conversation
const httpPostMessage = async (message) => {
  try {
    const response = await axiosInstance.post("/messages/", message, {
      withCredentials: true,
    });
    return response;
  } catch (err) {
    console.log(`Message could not be posted. Error: ${err}`);
    return null;
  }
};

export { httpGetConversations, httpGetConversationMessages, httpPostMessage };
