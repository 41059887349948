import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import _ from "lodash";

import { httpGetDetailedUser, httpGetUser } from "../apis/requestUser";
import ProgramCard from "../components/ProgramCard";
import Friend from "../components/Friend";
import LoadingScreen from "../components/LoadingScreen";

function OtherUserAccount() {
  let { id } = useParams();
  const [otherUser, setOtherUser] = useState({});
  const [otherUserFriends, setOtherUserFriends] = useState([]);

  //get detailed data of user
  useEffect(() => {
    const getDetailedUser = async () => {
      const response = await httpGetDetailedUser(id);
      if (response.status === 200) {
        setOtherUser(response.data.response);
      }
    };
    getDetailedUser();
  }, [id]);

  //get friends of user
  useEffect(() => {
    if (!_.isEmpty(otherUser)) {
      const getUserFriends = async () => {
        for (const friendId of otherUser.friends) {
          const friend = await httpGetUser(friendId);
          setOtherUserFriends((otherUserFriends) => [
            ...otherUserFriends,
            friend.data,
          ]);
        }
      };
      getUserFriends();
    }
  }, [otherUser]);

  if (_.isEmpty(otherUser)) {
    return <LoadingScreen />;
  }

  return (
    <div className="bg-lightGreen min-h-screen w-full flex justify-center h-max mt-8 pb-8">
      <div
        className="w-4/5 shadow-4xl rounded-xl bg-whiteBrown border-2 border-normalGreen flex py-8
      flex-col items-center gap-4"
      >
        <img
          className="h-56 w-56 rounded-full"
          src={process.env.REACT_APP_IMAGE_URL + otherUser.profileImg}
          alt=""
        />
        <h1 className="text-4xl">{otherUser.username}</h1>
        <div className="w-full flex flex-col gap-4 m-4 px-6">
          <div>
            <label className="text-xl font-bold">Email</label>
            <h2 className="text-xl">{otherUser.email}</h2>
          </div>
          <div>
            <label className="text-xl font-bold">Favorite traning type</label>
            <h2 className="text-xl capitalize">{otherUser.favoriteTraning}</h2>
          </div>
        </div>
        <div className="w-full flex flex-col m-4 p-2">
          <h2 className="text-2xl m-4 p-2 border-b-2 border-b-brown">
            Friends
          </h2>
          <div className="px-4">
            {otherUserFriends.length > 0 ? (
              <div>
                {otherUserFriends.map((friend) => {
                  return (
                    <Friend
                      key={friend._id}
                      friend={friend}
                      sentFriendRequests={false}
                      friendRelationship={true}
                      searchedFor={false}
                      renderDeleteButton={false}
                    />
                  );
                })}
              </div>
            ) : (
              <div>0 friends</div>
            )}
          </div>
        </div>
        {/*display programs made by user*/}
        <div className="w-full">
          <h2 className="text-2xl m-4 p-2 border-b-2 border-b-brown">
            Programs by {otherUser.username}
          </h2>
          <div>
            {otherUser.programs.length > 0 ? (
              <div className="w-full grid sm:grid-cols-1 lg:grid-cols-3 md:grid-cols-2 gap-2 text-center py-4 px-6">
                {otherUser.programs.map((program) => {
                  return (
                    <ProgramCard
                      key={program._id}
                      className="p-10 border-brown border rounded-2xl hover:text-white hover:bg-brown group"
                      program={program}
                    />
                  );
                })}
              </div>
            ) : (
              <div className="py-4 px-6">0 programs</div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default OtherUserAccount;
