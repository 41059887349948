import axios from "axios";

import { axiosInstance } from "./api";

//get request to rapid api endpoint to get macros
const httpGetMacros = async (formValues) => {
  try {
    const { age, gender, height, weight, activitylevel, goal } = formValues;
    //get number for activity level
    const activityNumber = activitylevel.match(/\d+/)[0];
    return await axios.get(
      "https://fitness-calculator.p.rapidapi.com/macrocalculator",
      {
        params: {
          age: age,
          weight: weight,
          height: height,
          gender: gender,
          activitylevel: activityNumber,
          goal: goal,
        },
        headers: {
          "X-RapidAPI-Host": "fitness-calculator.p.rapidapi.com",
          "X-RapidAPI-Key": process.env.REACT_APP_X_RAPIDAPI_KEY,
        },
      }
    );
  } catch (e) {
    console.log(`Could not get macros. Error: ${e}`);
    return null;
  }
};

//save diet plan to database
const httpSaveDiet = async (diet, id) => {
  try {
    const response = await axiosInstance.post(`/diet/${id}`, diet, {
      withCredentials: true,
    });
    return response;
  } catch (err) {
    console.log(`Diet could not be stored. Error: ${err}`);
    return null;
  }
};

//get diet from database
const httpGetDiet = async (userId) => {
  try {
    const response = await axiosInstance.get(`/diet/${userId}`, {
      withCredentials: true,
    });
    return response;
  } catch (err) {
    console.log(`Diet could not be fetched. ${err}`);
    return null;
  }
};

export { httpGetMacros, httpSaveDiet, httpGetDiet };
