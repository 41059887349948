import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { Disclosure, Menu } from "@headlessui/react";
import { MenuIcon, XIcon, LoginIcon } from "@heroicons/react/outline";

import CustomButton from "./CustomButton";
import UserIcon from "./UserIcon";

const menyItems = [
  { name: "Home", href: "/" },
  { name: "Programs", href: "/programs" },
];

const mobileMenyItems = [
  { name: "Register Now", href: "/register" },
  { name: "Login", href: "/login" },
];

const Navbar = ({ isSignedIn, username, profileImg }) => {
  return (
    <Disclosure as="nav" className="bg-darkGreen">
      {({ open }) => (
        <>
          <div className="max-w-6xl mx-auto px-2 sm:px-6 lg:px-8">
            <div className="relative flex items-center justify-between h-36">
              <div className=" flex flex-row sm:flex-col w-full text-white h-full content-end justify-end  ">
                <div className="absolute inset-y-0 left-0 flex items-center sm:hidden">
                  {/* Collapsible button*/}
                  <Disclosure.Button className="inline-flex items-center justify-center p-2 rounded-md  hover:text-white focus:outline-none ">
                    {open ? (
                      <XIcon className="block h-6 w-6" aria-hidden="true" />
                    ) : (
                      <MenuIcon className="block h-6 w-6" aria-hidden="true" />
                    )}
                  </Disclosure.Button>
                </div>
                <div className="sm:w-full w-3/5 flex items-center justify-between w-100  ">
                  {/* Logo */}
                  <img
                    className="hidden sm:block h-20 w-20"
                    src="/images/ratefitness-logo.png"
                    alt="Workflow"
                  />
                  <img
                    className="block sm:hidden h-20 w-20"
                    src="/images/ratefitness-logo.png"
                    alt=""
                  />

                  {/* Show profile OR register/login buttons */}
                  {isSignedIn ? (
                    <UserIcon profileImg={profileImg} username={username} />
                  ) : (
                    <div>
                      {/* Check that isSingedIn has been set before rendering buttons */}
                      {isSignedIn !== null && (
                        <div className="flex gap-4 hidden sm:flex ">
                          <CustomButton
                            buttonStyle="bg-brown text-white"
                            buttonText={"Register Now"}
                            icon={
                              <LoginIcon
                                className="block h-6 w-6"
                                aria-hidden="true"
                              />
                            }
                            link={"/register"}
                          />
                          <CustomButton
                            buttonStyle="bg-brown text-white"
                            buttonText={"Log in"}
                            icon={
                              <LoginIcon
                                className="block h-6 w-6"
                                aria-hidden="true"
                              />
                            }
                            link={"/login"}
                          />
                        </div>
                      )}
                    </div>
                  )}
                </div>
                {/* Menu items */}
                <div className="hidden sm:flex mt-2">
                  <div className="flex space-x-4">
                    {menyItems.map((item) => (
                      <a
                        key={item.name}
                        href={item.href}
                        className={
                          "hover:text-lightBrown px-3 py-2  text-sm font-medium"
                        }
                      >
                        {item.name}
                      </a>
                    ))}
                    {/* Dropdown menus */}
                    <Menu as="div" className="ml-3 relative">
                      <div>
                        <Menu.Button className="hover:text-lightBrown px-3 py-2  text-sm font-medium">
                          Social
                        </Menu.Button>
                      </div>
                      <Menu.Items className="absolute w-28  shadow-2xl bg-darkGreen border-t-normalGreen border-t-2 ">
                        <Menu.Item>
                          <div className="text-sm hover:bg-brown mb-2 p-2">
                            <a href="/friends">Find friends</a>
                          </div>
                        </Menu.Item>
                        <Menu.Item>
                          <div className="text-sm hover:bg-brown p-2">
                            <a href="/chat">Online chat</a>
                          </div>
                        </Menu.Item>
                      </Menu.Items>
                    </Menu>
                    <Menu as="div" className="ml-3 relative">
                      <div>
                        <Menu.Button className="hover:text-lightBrown px-3 py-2  text-sm font-medium">
                          Fitness related
                        </Menu.Button>
                      </div>
                      <Menu.Items className="absolute w-28  shadow-2xl bg-darkGreen border-t-normalGreen border-t-2 ">
                        <Menu.Item>
                          <div className="text-sm hover:bg-brown mb-2 p-2">
                            <a href="/bmi-calculator">BMI Calculator</a>
                          </div>
                        </Menu.Item>
                        <Menu.Item>
                          <div className="text-sm hover:bg-brown p-2">
                            <a href="/nutrition">Nutrition</a>
                          </div>
                        </Menu.Item>
                      </Menu.Items>
                    </Menu>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <Disclosure.Panel className="sm:hidden">
            <div className="px-2 pt-2 pb-3 space-y-1">
              {menyItems.map((item) => (
                <Disclosure.Button
                  key={item.name}
                  as={Link}
                  to={item.href}
                  className={
                    "hover:text-lightBrown text-white block px-3 py-2 text-base font-medium flex items-center"
                  }
                >
                  <button>{item.name}</button>
                </Disclosure.Button>
              ))}
              {!isSignedIn && (
                <div>
                  {mobileMenyItems.map((item) => (
                    <Disclosure.Button
                      key={item.name}
                      as={Link}
                      to={item.href}
                      className={
                        "hover:text-lightBrown text-white block px-3 py-2 text-base font-medium flex items-center"
                      }
                    >
                      <LoginIcon
                        className={"block h-6 w-6  hover:text-lightBrown"}
                        aria-hidden="true"
                      />
                      <button>{item.name}</button>
                    </Disclosure.Button>
                  ))}
                </div>
              )}
            </div>
          </Disclosure.Panel>
        </>
      )}
    </Disclosure>
  );
};

const mapStateToProps = (state) => {
  return {
    isSignedIn: state.auth.isSignedIn,
    username: state.auth.username,
    profileImg: state.auth.profileImg,
  };
};

export default connect(mapStateToProps, {})(Navbar);
