import React from "react";
import { connect } from "react-redux";
import _ from "lodash";

import ChatFriend from "./ChatFriend";

const FriendList = ({ isSignedIn, conversations, userId }) => {
  return (
    <div className="w-full p-2 flex flex-col items-center gap-2 bg-whiteBrown rounded h-screen-2/3 overflow-y-auto">
      <h1 className="text-2xl p-3 flex justify-center border-b border-brown w-full">
        Friends
      </h1>
      {isSignedIn &&
        conversations &&
        conversations.length > 0 &&
        conversations.map((conversation) => {
          return (
            <ChatFriend
              key={conversation._id}
              conversationId={conversation._id}
              displayStatus={true}
              friendId={
                conversation.members[0] === userId
                  ? conversation.members[1]
                  : conversation.members[0]
              }
            />
          );
        })}
    </div>
  );
};

const mapStateToProps = (state) => {
  const conversations = _.isEmpty(state.chat) ? [] : state.chat.conversations;
  const userId = state.auth.isSignedIn ? state.auth.id : "";

  return {
    isSignedIn: state.auth.isSignedIn,
    conversations: conversations,
    userId: userId,
  };
};

export default connect(mapStateToProps, {})(FriendList);
