import React from "react";
import { Field, reduxForm } from "redux-form";

import { wideRenderField } from "../utils/FieldComponents";
import CustomButton from "../../components/CustomButton";

const validate = (values) => {
  const errors = {};
  if (!values.weight) {
    errors.weight = "Required";
  } else if (values.weight < 40) {
    errors.weight = "Weight must be at least 40 kg";
  } else if (values.weight > 160) {
    errors.weight = "Weight must be less than 160";
  }
  if (!values.height) {
    errors.height = "Required";
  } else if (values.height < 130) {
    errors.height = "Height must be at least 130 cm";
  } else if (values.height > 230) {
    errors.height = "Height must be at least 230 cm";
  }
  return errors;
};

const BmiCalculatorForm = ({ handleSubmit, onSubmitForm }) => {
  const onSubmit = (formValues) => {
    onSubmitForm(formValues);
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="w-full">
      <label className="text-xl mb-2">Weight (kg)</label>
      <Field
        name="weight"
        type="number"
        min="1"
        component={wideRenderField}
        label="Enter your weight"
      />
      <label className="text-xl mb-2">Height (cm)</label>
      <Field
        name="height"
        type="number"
        min="1"
        component={wideRenderField}
        label="Enter your height"
      />
      <div className="w-full flex justify-center">
        <CustomButton
          buttonStyle="px-12 bg-lightBrown hover:bg-brown text-white"
          type="submit"
          buttonText={"Calculate"}
        />
      </div>
    </form>
  );
};

export default reduxForm({
  form: "bmi",
  validate,
})(BmiCalculatorForm);
