import React, { useState } from "react";
import { connect } from "react-redux";

import { Menu } from "@headlessui/react";
import { logout } from "../actions/UserActions";
import CustomButton from "./CustomButton";
import LoadingImg from "./LoadingImg";

const UserIcon = ({
  profileImg,
  username,
  numberOfFriendRequests,
  userId,
  logout,
}) => {
  const [imageLoaded, setImageLoaded] = useState(false);

  return (
    <Menu as="div" className="ml-3 relative">
      <div>
        <Menu.Button className="hover:text-lightBrown px-3 py-2  text-sm font-medium">
          <div className="flex gap-2 items-center hover:text-lightBrown">
            {profileImg.length > 0 ? (
              <div className="flex gap-2 items-center ">
                {imageLoaded && <div className="text-xl">{username}</div>}
                <img
                  className="h-12 w-12 rounded-full"
                  src={process.env.REACT_APP_IMAGE_URL + profileImg}
                  alt=""
                  onLoad={() => setImageLoaded(true)}
                />
                {!imageLoaded && <LoadingImg size={12} color={"darkGreen"} />}
              </div>
            ) : (
              <div className="text-4xl">{username}</div>
            )}
          </div>
        </Menu.Button>
      </div>
      <Menu.Items className="absolute w-28  shadow-2xl bg-darkGreen border-normalGreen border-2 ">
        <Menu.Item>
          <div className="text-sm hover:bg-brown mb-2 p-2 px-4">
            <a href="/account">Account</a>
          </div>
        </Menu.Item>
        <Menu.Item>
          <div className="text-sm hover:bg-brown mb-2 p-2 px-4">
            <a href="/health">Health</a>
          </div>
        </Menu.Item>
        <Menu.Item>
          <div className="text-sm hover:bg-brown p-2 px-4">
            <a href={`/friends/${userId}`} className="flex items-center ">
              <div>Friends</div>
              {numberOfFriendRequests > 0 && (
                <div className="flex justify-center items-center w-4 h-4 text-xs rounded-full relative bg-red left-0.5 bottom-1">
                  {numberOfFriendRequests}
                </div>
              )}
            </a>
          </div>
        </Menu.Item>
        <Menu.Item>
          <div className="text-sm hover:bg-brown">
            <CustomButton buttonText={"Logout"} handleClick={() => logout()} />
          </div>
        </Menu.Item>
      </Menu.Items>
    </Menu>
  );
};

const mapStateToProps = (state) => {
  const numberOfFriendRequests = state.auth.friendRequests
    ? state.auth.friendRequests.length
    : 0;
  const userId = state.auth.id;
  const isSignedIn = state.auth.isSignedIn;

  return {
    numberOfFriendRequests,
    userId,
    isSignedIn,
  };
};

export default connect(mapStateToProps, { logout })(UserIcon);
