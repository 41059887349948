import { axiosInstance } from "./api";

//get user friends
const httpGetFriends = async (userId) => {
  try {
    const response = await axiosInstance.get(`/friends/find/${userId}`, {
      withCredentials: true,
    });
    return response;
  } catch (err) {
    console.log(`Friend could not be found. Error: ${err}`);
  }
};

//get username and profile image of user
const httpSendFriendRequest = async (senderId, receiverId) => {
  try {
    const response = await axiosInstance.post(
      `/friends/friend-request`,
      { senderId: senderId, receiverId: receiverId },
      {
        withCredentials: true,
      }
    );
    return response;
  } catch (err) {
    console.log(`Friend request could not be sent. Error: ${err}`);
  }
};

//get username and profile image of user
const httpGetSentFriendRequests = async (userId) => {
  try {
    const response = await axiosInstance.get(
      `/friends/requests-and-friends/${userId}`,
      {
        withCredentials: true,
      }
    );
    return response;
  } catch (err) {
    console.log(`Sent friend requests could not be fetched. Error: ${err}`);
  }
};

//accept friend request
const httpAcceptFriendRequest = async (senderId, receiverId) => {
  try {
    const response = await axiosInstance.post(
      `/friends/friend-request/accept`,
      { senderId: senderId, receiverId: receiverId },
      {
        withCredentials: true,
      }
    );
    return response;
  } catch (err) {
    console.log(`Friend request could not be accepted. Error: ${err}`);
  }
};

//decline friend request
const httpDeclineFriendRequest = async (senderId, receiverId) => {
  try {
    const response = await axiosInstance.post(
      `/friends/friend-request/decline`,
      { senderId: senderId, receiverId: receiverId },
      {
        withCredentials: true,
      }
    );
    return response;
  } catch (err) {
    console.log(`Friend request could not be delinced. Error: ${err}`);
  }
};

//decline friend request
const httpDeleteFriend = async (friend, user) => {
  try {
    await axiosInstance.delete(`/friends/${user}/${friend}`, {
      withCredentials: true,
    });
  } catch (err) {
    console.log(`Friend could not be deleted. Error: ${err}`);
  }
};

export {
  httpGetSentFriendRequests,
  httpSendFriendRequest,
  httpAcceptFriendRequest,
  httpDeclineFriendRequest,
  httpGetFriends,
  httpDeleteFriend,
};
