import React from "react";
import DietCard from "./DietCard";

const Diets = ({ macros }) => {
  return (
    <div className="text-md w-full flex flex-col justify-center gap-2">
      <DietCard name="Balanced" value="balanced" macros={macros} />
      <DietCard name="High protein" value="highprotein" macros={macros} />
      <DietCard name="Low carbs" value="lowcarbs" macros={macros} />
      <DietCard name="Low fat" value="lowfat" macros={macros} />
    </div>
  );
};

export default Diets;
