import React, { useState } from "react";
import { reduxForm } from "redux-form";
import { connect } from "react-redux";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import Workout from "../Workout";
import CustomButton from "../../../../components/CustomButton";
import { convertDate } from "../DateFunctions";

const DayTemplate = ({ workoutDates, previewFlag }) => {
  const [workoutDate, setWorkoutDate] = useState("");
  const [workouts, setWorkouts] = useState(workoutDates);

  const deselectWorkout = (selectedWorkout) => {
    setWorkouts(
      workouts.filter((workoutDate) => workoutDate !== selectedWorkout)
    );
  };

  return (
    <div className="w-full gap-0">
      {!previewFlag && (
        <div className="flex justify-between w-min gap-4">
          <DatePicker
            className="rounded p-2 outline-none"
            placeholderText="Workout Date"
            value={workoutDate}
            onChange={(date) => setWorkoutDate(convertDate(date))}
          />
          <CustomButton
            buttonStyle="w-18 bg-lightGreen hover:bg-normalGreen hover:text-white text-black"
            type="button"
            buttonText={"Add"}
            handleClick={() =>
              workoutDate !== "" &&
              !workouts.includes(workoutDate) &&
              workouts.length <= 20 &&
              setWorkouts([...workouts, workoutDate])
            }
          />
        </div>
      )}
      <div>
        {workouts &&
          workouts.sort().map((workoutDate) => {
            return (
              <Workout
                previewFlag={previewFlag}
                date={workoutDate}
                key={workoutDate}
                deselectWorkout={deselectWorkout}
              />
            );
          })}
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  const workoutDates = [];
  //get workout dates in redux store
  // eslint-disable-next-line no-unused-vars
  for (const [key, value] of Object.entries(state.program)) {
    if (!workoutDates.includes(value.date)) {
      workoutDates.push(value.date);
    }
  }
  return { workoutDates: workoutDates };
};

const DayTemplateConnection = connect(mapStateToProps, {})(DayTemplate);

export default reduxForm({
  form: "programform",
  destroyOnUnmount: false,
})(DayTemplateConnection);
