export const SIGN_IN = "SIGN_IN";
export const CREATE_USER = "CREATE_USER";
export const UPDATE_USER = "UPDATE_USER";
export const DELETE_USER = "DELETE_USER";
export const SIGN_OUT = "SIGN_OUT";
export const ADD_EXERCISE = "ADD_EXERCISE";
export const DELETE_EXERCISE = "DELETE_EXERCISE";
export const DELETE_WORKOUT = "DELETE_WORKOUT";
export const GET_PROGRAMS = "GET_PROGRAMS";
export const CREATE_PROGRAM = "CREATE_PROGRAM";
export const GET_EXERCISES = "GET_EXERCISES";
export const LOAD_EXERCISES = "LOAD_EXERCISES";
export const EDIT_UPLOADED_PROGRAM = "EDIT_UPLOADED_PROGRAM";
export const LOAD_EDIT_VALUES = "LOAD_EDIT_VALUES";
export const DELETE_PROGRAM = "DELETE_PROGRAM";
export const SELECT_DIET = "SELECT_DIET";
export const LOAD_DIET = "LOAD_DIET";
export const SET_CONVERSATIONS = "SET_CONVERSATIONS";
export const SELECT_FRIEND = "SELECT_FRIEND";
export const CONVERSATION_MESSAGES = "CONVERSATION_MESSAGES";
export const ADD_MESSAGE = "ADD_MESSAGE";
export const ADD_SOCKET_MESSAGE = "ADD_SOCKET_MESSAGE";
export const ONLINE_USERS = "ONLINE_USERS";
