import React from "react";
import { reduxForm, Field } from "redux-form";

import { searchField } from "../utils/FieldComponents";

const validate = (values) => {
  const errors = {};
  if (!values.friendname) {
    errors.friendname = "Required";
  }
  return errors;
};

const FindFriendForm = (props) => {
  const { handleSubmit } = props;
  return (
    <form onSubmit={handleSubmit} className="w-full flex">
      <div className="w-full mb-4">
        <Field
          name="search"
          component={searchField}
          type="text"
          text="Search"
          label="Search for a username"
        />
      </div>
    </form>
  );
};

export default reduxForm({
  form: "finduser",
  validate,
})(FindFriendForm);
