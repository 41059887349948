import React from "react";
import { reduxForm, Field, reset } from "redux-form";

import { messageField } from "../utils/FieldComponents";

const validate = (values) => {
  const errors = {};
  if (!values.messageText) {
    errors.messageText = "Required";
  }
  return errors;
};

const MessageForm = (props) => {
  const { handleSubmit } = props;
  return (
    <form onSubmit={handleSubmit} className="w-full flex">
      <Field
        name="messageText"
        component={messageField}
        type="text"
        text="Send"
        label="Aa"
      />
    </form>
  );
};

const afterSubmit = (result, dispatch) => {
  dispatch(reset("chatmessage"));
};

export default reduxForm({
  form: "chatmessage",
  validate,
  onSubmitSuccess: afterSubmit,
})(MessageForm);
