import React from "react";

const Footer = () => {
  return (
    <footer className="p-4 bg-darkGreen text-white shadow flex justify-center w-full h-40 items-center">
      <div className="w-4/5 flex justify-between px-12">
        <span className="text-sm text-gray-500 sm:text-center dark:text-gray-400">
          © 2022{" "}
          <a
            href={process.env.REACT_APP_CLIENT_URL}
            className="hover:underline"
          >
            RateFitness
          </a>
          . All Rights Reserved.
        </span>

        <ul className="flex flex-wrap items-center mt-3 text-sm text-gray-500 dark:text-gray-400 sm:mt-0">
          <li>
            <a href="/" className="mr-4 hover:underline md:mr-6 ">
              About
            </a>
          </li>
          <li>
            <a href="/" className="mr-4 hover:underline md:mr-6">
              Privacy Policy
            </a>
          </li>
          <li>
            <a href="/" className="hover:underline">
              Contact
            </a>
          </li>
        </ul>
      </div>
    </footer>
  );
};

export default Footer;
