import { DELETE_USER, SIGN_IN, SIGN_OUT, UPDATE_USER } from "../actions/types";

const INITIAL_STATE = {
  isSignedIn: null,
};

const authReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SIGN_IN:
      return {
        ...state,
        isSignedIn: true,
        ...action.payload,
      };
    case DELETE_USER:
    case SIGN_OUT:
      return { isSignedIn: false };
    case UPDATE_USER:
      return { isSignedIn: true, ...action.payload };
    default:
      return state;
  }
};

export default authReducer;
