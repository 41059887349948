import { axiosInstance } from "./api";

//save program image
const httpUploadImage = async (imgData) => {
  try {
    const response = await axiosInstance.post("/image/upload", imgData, {
      withCredentials: true,
    });
    return response;
  } catch (err) {
    console.log(`Program image could not be uploaded. Error: ${err}`);
  }
};

//save program in backend
const httpPostProgram = async (programData) => {
  try {
    const response = await axiosInstance.post("/program", programData, {
      withCredentials: true,
    });
    return response;
  } catch (err) {
    console.log(`Program could not be posted. Error: ${err}`);
  }
};

//get all current programs from backend or filter all programs by username and training type
const httpGetAllPrograms = async (formValues) => {
  try {
    const textInput = formValues ? formValues.textInput : "";
    const trainingType = formValues ? formValues.trainingType : "";
    const response = await axiosInstance.get(
      `/program?textInput=${textInput}&trainingType=${trainingType}`,
      {
        withCredentials: true,
      }
    );
    return response;
  } catch (err) {
    console.log(`Programs could not be fetched. Error: ${err}`);
  }
};

//get specific program details
const httpGetProgram = async (id) => {
  try {
    const response = await axiosInstance.get(`/program/${id}`, {
      withCredentials: true,
    });
    return response;
  } catch (err) {
    console.log(`Programs could not be found. Error: ${err}`);
  }
};

const httpUpdateProgram = async (id, programValues) => {
  try {
    const response = await axiosInstance.put(`/program/${id}`, programValues, {
      withCredentials: true,
    });
    return response;
  } catch (err) {
    console.log(`Programs could not be updated. Error: ${err}`);
  }
};

const httpDeleteProgram = async (id) => {
  try {
    const response = await axiosInstance.delete(`/program/${id}`, {
      withCredentials: true,
    });
    return response;
  } catch (err) {
    console.log(`Programs could not be deleted. Error: ${err}`);
  }
};

const httpPostReview = async (reviewValues) => {
  try {
    const response = await axiosInstance.post("/program/review", reviewValues, {
      withCredentials: true,
    });
    return response;
  } catch (err) {
    console.log(`Review could not be posted. Error: ${err}`);
  }
};

const httpUpdateReview = async (reviewValues) => {
  try {
    const response = await axiosInstance.put(
      "/program/review/update",
      reviewValues,
      {
        withCredentials: true,
      }
    );
    return response;
  } catch (err) {
    console.log(`Review could not be updated. Error: ${err}`);
  }
};

const httpDeleteReview = async (reviewId, username) => {
  try {
    const response = await axiosInstance.delete(
      `/program/review/${reviewId}/${username}`,
      {
        withCredentials: true,
      }
    );
    return response;
  } catch (err) {
    console.log(`Review could not be deleted. Error: ${err}`);
  }
};

export {
  httpUploadImage,
  httpPostProgram,
  httpGetAllPrograms,
  httpGetProgram,
  httpUpdateProgram,
  httpDeleteProgram,
  httpPostReview,
  httpUpdateReview,
  httpDeleteReview,
};
