import React, { useState } from "react";
import { Link } from "react-router-dom";
import LoadingImg from "./LoadingImg";

const ProgramCard = ({ program }) => {
  const [imageLoaded, setImageLoaded] = useState(false);

  return (
    <Link to={`/programs/${program._id}`}>
      <div
        className={`shadow-xl h-96 bg-whiteBrown  rounded-xl ${
          imageLoaded && "hover:text-white hover:bg-lightBrown"
        }`}
      >
        {program.file.length > 0 ? (
          <div className="flex flex-col items-center w-full ">
            <img
              loading="lazy"
              className="w-full rounded-t-xl h-52 h-max-52"
              src={process.env.REACT_APP_IMAGE_URL + program.file}
              alt=""
              onLoad={() => setImageLoaded(true)}
            />
            {!imageLoaded && (
              <div className="w-full h-52 h-max-52 group mt-8">
                <LoadingImg size={12} color={"whiteBrown"} />
              </div>
            )}
            {imageLoaded && (
              <div className="px-2 w-full">
                <div className="xs flex justify-end w-full">
                  Author: {program.author}{" "}
                </div>
                <h1 className="text-xl mb">{program.programTitle}</h1>
                <div className="w-full flex flex-start">
                  <div className="text-sm bg-lightGreen text-white rounded-lg p-2">
                    {program.type}{" "}
                  </div>
                </div>
                <p className="overflow-auto">
                  {program.description.substring(0, 100)}...
                </p>
              </div>
            )}
          </div>
        ) : (
          <div>
            <div className="flex justify-center w-full flex-col min-h-80 p-2">
              <div className="xs flex justify-end w-full">
                Author: {program.author}{" "}
              </div>
              <h1 className="text-xl mb-2">{program.programTitle}</h1>
              <div className="w-full flex flex-start">
                <div className="text-sm bg-lightGreen text-white rounded-lg p-2">
                  {program.type}{" "}
                </div>
              </div>
              <p>{program.description.substring(0, 400)}...</p>
            </div>
          </div>
        )}
      </div>
    </Link>
  );
};

export default ProgramCard;
