import React from "react";
import { connect } from "react-redux";

import Macros from "./Macros";
import { selectDiet } from "../actions/DietActions";

function DietCard({ name, macros, value, selectDiet, diet }) {
  const defaultStyling =
    "border-2 p-2 border-normalGreen hover:bg-darkGreen hover:text-white rounded cursor-pointer";

  return (
    <div
      className={
        diet === name
          ? "bg-darkGreen hover:text-white" + defaultStyling
          : defaultStyling
      }
      onClick={() =>
        selectDiet({
          name: name,
          protein: macros[value].protein,
          fat: macros[value].fat,
          carbs: macros[value].carbs,
          calories: macros["calorie"],
        })
      }
    >
      <span className={diet === name ? "text-white font-bold" : "font-bold"}>
        {name}
      </span>{" "}
      <Macros
        selected={diet === name}
        protein={macros[value].protein}
        carbs={macros[value].carbs}
        fat={macros[value].fat}
      />
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    diet: state.diet.name,
  };
};

export default connect(mapStateToProps, { selectDiet })(DietCard);
