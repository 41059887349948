import {
  ADD_EXERCISE,
  DELETE_EXERCISE,
  DELETE_WORKOUT,
  GET_PROGRAMS,
  CREATE_PROGRAM,
  GET_EXERCISES,
  LOAD_EXERCISES,
  EDIT_UPLOADED_PROGRAM,
  LOAD_EDIT_VALUES,
  DELETE_PROGRAM,
} from "./types";
import {
  httpGetAllPrograms,
  httpPostProgram,
  httpGetProgram,
  httpDeleteProgram,
} from "../apis/requestProgram";
import history from "../routes/history";

//add exercise object to redux store
const addExercise = (exerciseObject) => async (dispatch) => {
  dispatch({ type: ADD_EXERCISE, payload: exerciseObject });
};

//remove exericse object from the redux store
const deleteExercise = (id) => async (dispatch) => {
  dispatch({ type: DELETE_EXERCISE, payload: id });
};

//delete the entire workout
const deleteWorkout = (date) => async (dispatch) => {
  dispatch({ type: DELETE_WORKOUT, payload: date });
};

//send POST http request to create program and reload page
const createProgram = (programData) => async (dispatch) => {
  const response = await httpPostProgram(programData);
  dispatch({ type: CREATE_PROGRAM, payload: "" });
  history.push("/programs");
  window.location.reload(true);
  return response;
};

//fetch all program from backend
const getAllPrograms = () => async (dispatch) => {
  const response = await httpGetAllPrograms();
  dispatch({ type: GET_PROGRAMS, payload: response });
};

//search for specific programs and add found programs to redux store
const searchPrograms = (formvalues) => async (dispatch) => {
  const response = await httpGetAllPrograms(formvalues);
  dispatch({ type: GET_PROGRAMS, payload: response });
};

//load exercises into program from for displaying workouts
const loadExercises = (exercises) => async (dispatch) => {
  dispatch({ type: LOAD_EXERCISES, payload: exercises });
};

//get detailed information about a program once clicked
const getProgram = (id) => async (dispatch) => {
  const response = await httpGetProgram(id);
  if (response && response.data) {
    dispatch({ type: GET_EXERCISES, payload: response.data.exercises });
    return response.data;
  }
};

//load in exericse values that may be edited when creating a program
const loadEditValues = (programValues) => async (dispatch) => {
  dispatch({ type: LOAD_EDIT_VALUES, payload: programValues });
};

//store the edited program
const editProgram = (programValues) => async (dispatch) => {
  dispatch({ type: EDIT_UPLOADED_PROGRAM, payload: programValues });
};

//delete program by id and navigate redirect to programs page
const deleteProgram = (programId) => async (dispatch) => {
  await httpDeleteProgram(programId);
  dispatch({ type: DELETE_PROGRAM });
  history.push("/programs");
  window.location.reload(true);
};

export {
  addExercise,
  deleteExercise,
  deleteWorkout,
  getAllPrograms,
  searchPrograms,
  createProgram,
  getProgram,
  loadExercises,
  loadEditValues,
  editProgram,
  deleteProgram,
};
