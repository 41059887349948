import React, { useState, useEffect, useRef } from "react";
import { connect } from "react-redux";
import _ from "lodash";
import TimeAgo from "javascript-time-ago";
import en from "javascript-time-ago/locale/en.json";

import Message from "./Message";
import { getConversationMessages, createMessage } from "../actions/ChatActions";
import MessageForm from "../forms/social/MessageForm";

//handle when a message was sent
TimeAgo.addDefaultLocale(en);

const Chatbox = ({
  friendSelected,
  getConversationMessages,
  createMessage,
  messages,
  userId,
  socket,
}) => {
  const [imageLoaded, setImageLoaded] = useState(false);
  const scrollRef = useRef();

  useEffect(() => {
    //if friend selected get all the messages from that conversation
    if (!_.isEmpty(friendSelected)) {
      getConversationMessages(friendSelected.conversationId);
    }
  }, [friendSelected, getConversationMessages]);

  //scroll to bottom whenever messages change
  useEffect(() => {
    scrollRef.current?.scrollIntoView({ behavior: "smooth" });
  }, [messages]);

  const onCreateMessage = async (formValues) => {
    if (!_.isEmpty(formValues)) {
      const message = {};
      message.text = formValues.messageText;
      message.sender = userId;
      message.conversationId = friendSelected.conversationId;

      //post message to database and add message to redux store
      const response = await createMessage(message);
      if (response && response.status === 200) {
        //send message via sockets to receiver
        socket.current.emit("sendMessage", {
          _id: response.data._id,
          senderId: userId,
          receiverId: friendSelected._id,
          text: formValues.messageText,
          conversationId: friendSelected.conversationId,
        });
      }
    }
  };

  if (_.isEmpty(friendSelected)) {
    return (
      <div className="w-full rounded flex flex-col">
        <div className=" flex items-center h-screen-2/3  flex-col">
          <div className="bg-brown w-full h-16"></div>
          <div className="bg-white w-full h-full flex justify-center items-center text-xl p-8">
            Select a conversation
          </div>
        </div>
        <div className="h-32 bg-white"></div>
      </div>
    );
  }

  return (
    <div className="w-full bg-white rounded">
      <div className="w-full flex flex-start p-2 bg-brown items-center gap-4">
        <img
          className="h-12 w-12 rounded-full"
          src={process.env.REACT_APP_IMAGE_URL + friendSelected.profileImg}
          alt=""
          onLoad={() => setImageLoaded(true)}
        />
        {imageLoaded && (
          <div className="text-lg">{friendSelected.username}</div>
        )}
      </div>
      <div className="flex flex-col gap-2 p-8 h-screen-2/3 overflow-y-auto">
        {messages.map((message) => {
          return (
            <div ref={scrollRef} key={message._id}>
              <Message
                messageText={message.text}
                ownMessage={userId === message.sender}
                time={message.createdAt}
              />
            </div>
          );
        })}
        {messages.length === 0 && (
          <div className="flex justify-center">
            Write something to
            <span className="font-bold pl-1">{friendSelected.username}</span>
          </div>
        )}
      </div>
      <div className="p-2">
        <MessageForm onSubmit={onCreateMessage} />
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  const friendSelected =
    state.chat && state.chat.friendSelected ? state.chat.friendSelected : {};
  const messages = state.chat && state.chat.messages ? state.chat.messages : [];
  return { userId: state.auth.id, friendSelected, messages };
};

export default connect(mapStateToProps, {
  getConversationMessages,
  createMessage,
})(Chatbox);
