import React from "react";
import ReactTimeAgo from "react-time-ago";
import { connect } from "react-redux";

const Message = ({ messageText, ownMessage, time, friendSelected }) => {
  const defaultStyling = "flex flex-col w-full mt-2";

  return (
    <div
      className={
        ownMessage === true ? "items-end" + defaultStyling : defaultStyling
      }
    >
      {/* decide if image or text should come first */}
      {ownMessage ? (
        <div className="flex flex-col items-end">
          <div className="flex gap-2">
            <div className="bg-messageBlue p-2 rounded-xl">
              <p className={ownMessage ? "text-white" : ""}>{messageText}</p>
            </div>
          </div>
          <div className="text-xs">
            <ReactTimeAgo date={new Date(time)} locale="en-US" />
          </div>
        </div>
      ) : (
        <div>
          <div className="flex gap-2">
            <img
              className="h-8 w-8 rounded-full"
              src={process.env.REACT_APP_IMAGE_URL + friendSelected.profileImg}
              alt=""
            />
            <div className={"bg-messageGray p-2 px-4 rounded-2xl"}>
              <p className={ownMessage ? "text-white" : ""}>{messageText}</p>
            </div>
          </div>
          <div className="text-xs">
            <ReactTimeAgo date={new Date(time)} locale="en-US" />
          </div>
        </div>
      )}
    </div>
  );
};

const mapStateToProps = (state) => {
  const friendSelected =
    state.chat && state.chat.friendSelected ? state.chat.friendSelected : {};

  return { friendSelected };
};

export default connect(mapStateToProps, {})(Message);
