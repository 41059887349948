import React from "react";
import { Field, reduxForm } from "redux-form";

import { wideRenderField } from "../utils/FieldComponents";
import CustomButton from "../../components/CustomButton";
import { ACTIVITY_LEVELS } from "../utils/ActivityLevels";
import { GOALS } from "../utils/Goals";

const validate = (values) => {
  const errors = {};
  //weight
  if (!values.weight) {
    errors.weight = "Required";
  } else if (values.weight < 40) {
    errors.weight = "Weight must be at least 40 kg";
  } else if (values.weight > 160) {
    errors.weight = "Weight must be less than 160";
  }
  //height
  if (!values.height) {
    errors.height = "Required";
  } else if (values.height < 130) {
    errors.height = "Height must be at least 130 cm";
  } else if (values.height > 230) {
    errors.height = "Height must be at least 230 cm";
  }
  //gender
  if (!values.gender) {
    errors.gender = "Required";
  }
  //age
  if (!values.age) {
    errors.age = "Required";
  } else if (values.age > 80) {
    errors.age = "Age cannot be be bigger than 80";
  }
  return errors;
};

function NutritionForm({ handleSubmit, onSubmitForm }) {
  const onSubmit = (formValues) => {
    onSubmitForm(formValues);
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="w-full">
      <label className="text-xl mb-2">Gender</label>
      <div className="mb-2 flex gap-4">
        <label>
          <Field name="gender" component="input" type="radio" value="male" />{" "}
          Male
        </label>
        <label>
          <Field name="gender" component="input" type="radio" value="female" />{" "}
          Female
        </label>
      </div>
      <label className="text-xl">Age (year)</label>
      <Field
        name="age"
        type="number"
        min="1"
        component={wideRenderField}
        label="Enter your age"
      />

      <label className="text-xl">Height (cm)</label>
      <Field
        name="height"
        type="number"
        min="1"
        component={wideRenderField}
        label="Enter your height"
      />
      <label className="text-xl">Weight (kg)</label>
      <Field
        name="weight"
        type="number"
        min="1"
        component={wideRenderField}
        label="Enter your weight"
      />
      <label className="text-xl">Activity level</label>
      <Field
        name="activitylevel"
        component="select"
        className="mb-8 py-2 w-full border-lightGray rounded-lg border-2"
      >
        {ACTIVITY_LEVELS.map((type) => {
          return (
            <option key={type.value} value={type.value}>
              {type.dropdownText}
            </option>
          );
        })}
      </Field>

      <label className="text-xl">Goal</label>
      <Field
        name="goal"
        component="select"
        className="mb-8 py-2 w-full border-lightGray rounded-lg border-2"
      >
        {GOALS.map((type) => {
          return (
            <option key={type.value} value={type.value}>
              {type.dropdownText}
            </option>
          );
        })}
      </Field>

      <div className="w-full flex justify-center">
        <CustomButton
          buttonStyle="px-12 bg-lightBrown hover:bg-brown text-white"
          type="submit"
          buttonText={"Calculate"}
        />
      </div>
    </form>
  );
}

export default reduxForm({
  form: "nutritionform",
  validate,
})(NutritionForm);
