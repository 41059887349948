import React from "react";
import { reduxForm, Field } from "redux-form";

import CustomButton from "../../components/CustomButton";
import { wideRenderField } from "../utils/FieldComponents";

const validate = (values) => {
  const errors = {};
  if (!values.programTitle) {
    errors.programTitle = "Required";
  } else if (values.programTitle.length < 4) {
    errors.programTitle = "Title must be at least 4 characters";
  } else if (values.programTitle.length > 50) {
    errors.programTitle = "Title must be less then 50 characters";
  }
  return errors;
};

const PageOneForm = (props) => {
  const { handleSubmit } = props;
  return (
    <form onSubmit={handleSubmit} className="w-full">
      <div>
        <label>Program Title</label>
        <Field
          name="programTitle"
          component={wideRenderField}
          type="text"
          label="Enter a title for the program"
        />
      </div>
      <label>How should the program be displayed?</label>
      <div className="flex gap-4 mb-8">
        <label>
          <Field name="timePeriod" component="input" type="radio" value="day" />
          Day-view
        </label>
        <label>
          <Field
            name="timePeriod"
            component="input"
            type="radio"
            value="week"
          />
          Week-view
        </label>
        <label>
          <Field
            name="timePeriod"
            component="input"
            type="radio"
            value="month"
          />
          Month-view
        </label>
      </div>
      <div className="flex w-full justify-center">
        <CustomButton
          buttonStyle="bg-lightGreen border-2 border-lightGreen hover:bg-lightGreen hover:text-white text-black"
          type="submit"
          buttonText={"Next"}
        />
      </div>
    </form>
  );
};

export default reduxForm({
  form: "programform",
  validate,
  destroyOnUnmount: false,
})(PageOneForm);
