import React from "react";
import { connect } from "react-redux";

import PageTwoForm from "./PageTwoForm";
import CustomButton from "../../components/CustomButton";
import ErrorMessage from "../../components/ErrorMessage";

const ProgramPreview = ({
  formValues,
  file,
  previousPage,
  onSumbitForm,
  errorFlag,
}) => {
  return (
    <div className="w-full flex flex-col gap-8">
      <h1 className="uppercase text-2xl flex justify-center border-b-2 border-brown">
        {formValues.programTitle}
      </h1>
      {file && (
        <div className="flex justify-center">
          <img
            className="max-h-96 rounded"
            src={URL.createObjectURL(file)}
            alt=""
          />
        </div>
      )}
      <div>
        <label className="text-lg font-bold">Type of program</label>
        <div className="capitalize">{formValues.type}</div>
      </div>
      <div>
        <label className="text-lg font-bold">Description</label>
        <div className="w-full overflow-y-auto h-min max-h-60  border-t border-b border-brown p-4">
          {formValues.description}
        </div>
      </div>
      <div>
        <label className="text-lg font-bold">Program</label>
        <div className="border-t border-b border-brown p-4">
          <PageTwoForm previewFlag={true} />
        </div>
      </div>
      {errorFlag && <ErrorMessage text={errorFlag} />}

      {/* form element to handle submit button */}
      <div className="flex gap-4 w-full justify-center">
        <CustomButton
          buttonStyle="bg-lightBrown border-2 border-lightBrown hover:bg-brown hover:border-brown hover:text-white text-black"
          type="button"
          handleClick={previousPage}
          buttonText={"Previous"}
        />
        <CustomButton
          buttonStyle="bg-lightGreen border-2 border-lightGreen hover:bg-lightGreen hover:text-white text-black"
          type="submit"
          handleClick={onSumbitForm}
          buttonText={"Create program"}
        />
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    formValues: state.form.programform.values,
  };
};

export default connect(mapStateToProps, {})(ProgramPreview);
