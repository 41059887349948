import { combineReducers } from "redux";
import { reducer as formReducer } from "redux-form";
import authReducer from "./AuthReducer";
import programReducer from "./ProgramReducer";
import fetchProgramReducer from "./FetchProgramReducer";
import dietReducer from "./DietReducer";
import chatReducer from "./ChatReducer";

export default combineReducers({
  auth: authReducer,
  form: formReducer,
  fetchedPrograms: fetchProgramReducer,
  diet: dietReducer,
  chat: chatReducer,
  program: programReducer,
});
