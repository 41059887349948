import React, { useState } from "react";
import { connect } from "react-redux";
import LoginForm from "../forms/LoginForm";
import { login } from "../actions/UserActions";

const Login = ({ login }) => {
  const [errorFlag, setErrorFlag] = useState(false);

  const onSubmitForm = async (formValues) => {
    const response = await login(formValues);
    if (response === null) {
      setErrorFlag(true);
    }
  };

  return (
    <div className="bg-lightGreen h-screen pb-10 w-full flex justify-center">
      <div className="flex flex-col items-center w-3/5 mt-28 shadow-2xl rounded-xl h-fit p-8 bg-whiteBrown">
        <h2 className="text-4xl mb-8">Login</h2>
        {<LoginForm onSubmitForm={onSubmitForm} errorFlag={errorFlag} />}
      </div>
    </div>
  );
};

export default connect(null, { login })(Login);
