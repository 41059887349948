export const GOALS = [
  {
    value: "maintain",
    dropdownText: "Maintain weight",
  },
  {
    value: "mildlose",
    dropdownText: "Mild weight loss",
  },
  {
    value: "weightlose",
    dropdownText: "Weight loss",
  },
  {
    value: "extremelose",
    dropdownText: "Extreme weight loss",
  },
  {
    value: "mildgain",
    dropdownText: "Mild weight gain",
  },
  {
    value: "weightgain",
    dropdownText: "Weight gain",
  },
  {
    value: "extremegain",
    dropdownText: "Extreme weight gain",
  },
];
