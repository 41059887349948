import React from "react";
import { Disclosure } from "@headlessui/react";

import { getDatesOfWeek } from "../DateFunctions";

const WeekComponent = ({ workoutCallback, numberOfWeek, workoutDates }) => {
  const DAY_NAMES = [
    { name: "Monday" },
    { name: "Tuesday" },
    { name: "Wednesday" },
    { name: "Thursday" },
    { name: "Friday" },
    { name: "Saturday" },
    { name: "Sunday" },
  ];

  const datesOfWeek = getDatesOfWeek(numberOfWeek);

  //merge key array and value array
  const dateList = DAY_NAMES.map((day, index) => {
    day.date = datesOfWeek[index];
    return day;
  });

  return (
    <Disclosure>
      <div className="mb-4">
        <Disclosure.Button className="text-2xl py-2 border-2 border-brown w-full hover:bg-brown rounded">
          Week {numberOfWeek}
        </Disclosure.Button>
        <Disclosure.Panel className="text-gray-500">
          <div className="flex w-full">
            {dateList.map((day) => {
              return (
                <div
                  className="w-full text-center grow hover:bg-lightBrown hover:cursor-pointer"
                  key={day.date}
                  onClick={() => workoutCallback(day.date)}
                >
                  <p>{day.name}</p>
                  <p className="text-xs">{day.date}</p>
                  {/*add a dot if workout exists on the specific day*/}
                  {workoutDates.includes(day.date) && (
                    <span className="h-2 w-2 bg-brown rounded inline-block"></span>
                  )}
                </div>
              );
            })}
          </div>
        </Disclosure.Panel>
      </div>
    </Disclosure>
  );
};

export default WeekComponent;
