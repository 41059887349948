import React from "react";

const Exercise = ({ exerciseObject }) => {
  return (
    <div className="flex w-4/5 justify-between border-b border-lightBrown">
      <div className="w-full">{exerciseObject.number}</div>
      <div className="w-full overflow-y-auto">{exerciseObject.exercise}</div>
      <div className="w-full">{exerciseObject.reps}</div>
      <div className="w-full">{exerciseObject.sets}</div>
      <div className="w-full">{exerciseObject.rest}</div>
    </div>
  );
};

export default Exercise;
