import {
  SET_CONVERSATIONS,
  SELECT_FRIEND,
  CONVERSATION_MESSAGES,
  ADD_MESSAGE,
  ADD_SOCKET_MESSAGE,
  ONLINE_USERS,
} from "./types";
import {
  httpGetConversations,
  httpGetConversationMessages,
  httpPostMessage,
} from "../apis/requestChat";

//fetch conversations and set conversations in redux store
const getConversations = (userId) => async (dispatch) => {
  const response = await httpGetConversations(userId);
  if (response.status === 200) {
    dispatch({ type: SET_CONVERSATIONS, payload: response.data });
  }
};

//fetch messages for conversation by id
const getConversationMessages = (conversationId) => async (dispatch) => {
  const response = await httpGetConversationMessages(conversationId);
  if (response.status === 200) {
    dispatch({ type: CONVERSATION_MESSAGES, payload: response.data });
  }
};

//post message for conversation and add message to redux store
const createMessage = (message) => async (dispatch) => {
  const response = await httpPostMessage(message);
  if (response.status === 200) {
    dispatch({ type: ADD_MESSAGE, payload: response.data });
    return response;
  }
};

//set selected friend
const selectFriend = (friend) => async (dispatch) => {
  dispatch({ type: SELECT_FRIEND, payload: friend });
};

//add message from socket to redux store
const addSocketMessage = (message) => async (dispatch) => {
  //add time for when message was added
  message.createdAt = Date.now();
  dispatch({ type: ADD_SOCKET_MESSAGE, payload: message });
};

//add message from socket to redux store
const updateOnlineUsers = (onlineUsers) => async (dispatch) => {
  dispatch({ type: ONLINE_USERS, payload: onlineUsers });
};

export {
  getConversations,
  getConversationMessages,
  selectFriend,
  createMessage,
  addSocketMessage,
  updateOnlineUsers,
};
