import React from "react";
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
import CustomButton from "./CustomButton";

export const ExportCSV = ({ csvData, fileName }) => {
  const fileType =
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
  const fileExtension = ".xlsx";

  const exportToCSV = () => {
    //exclude _id, id, __v from each exercise object
    const filteredCsvData = csvData.map(
      ({ _id, id, __v, ...filterData }) => filterData
    );
    const header = {
      header: ["date", "number", "exercise", "reps", "sets", "rest"],
    };
    const ws = XLSX.utils.json_to_sheet(filteredCsvData, header);
    //rename headers
    ws.A1.v = "Date";
    ws.B1.v = "Order #";
    ws.C1.v = "Exercise";
    ws.D1.v = "Reps";
    ws.E1.v = "Sets";
    ws.F1.v = "Rest (sec)";
    const wb = { Sheets: { program: ws }, SheetNames: ["program"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, fileName + fileExtension);
  };

  return (
    <CustomButton
      buttonStyle={
        "px-4 w-max bg-whiteBrown border-2 border-brown hover:bg-lightGreen hover:text-white text-black"
      }
      buttonText={"Download Excel"}
      handleClick={(e) => exportToCSV()}
    />
  );
};
