import _ from "lodash";

import {
  GET_PROGRAMS,
  GET_EXERCISES,
  EDIT_UPLOADED_PROGRAM,
  LOAD_EDIT_VALUES,
} from "../actions/types";

const fetchProgramReducer = (state = {}, action) => {
  switch (action.type) {
    case GET_PROGRAMS:
      return {
        ...state,
        programs: { ..._.mapKeys(action.payload.data, "_id") },
      };
    case GET_EXERCISES:
      return {
        ...state,
        exercises: { ..._.mapKeys(action.payload, "id") },
      };
    case LOAD_EDIT_VALUES:
      return { ...state, editProgram: action.payload };
    case EDIT_UPLOADED_PROGRAM:
      const { newProgramValue, key } = action.payload;
      return {
        ...state,
        editProgram: { ...state.editProgram, [key]: newProgramValue },
      };

    default:
      return state;
  }
};

export default fetchProgramReducer;
