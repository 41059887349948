import React, { useState } from "react";
import { Field, reduxForm } from "redux-form";

import ErrorMessage from "../components/ErrorMessage";
import { wideRenderField } from "./utils/FieldComponents";
import TraningTypeDropdown from "./utils/TraningTypeDropdown";
import CustomButton from "../components/CustomButton";

const validate = (values) => {
  const errors = {};
  //username
  if (!values.username || values.username.length < 3) {
    errors.username = "Must contain at least 3 characters";
  } else if (values.username.length > 15) {
    errors.username = "Must be 15 characters or less";
  }
  //password
  if (!values.password && values.passwordCopy) {
    errors.password = "Required";
  }
  if (values.password && values.password.length < 8) {
    errors.password = "Password needs to be be at least 8 characters";
  }
  if (values.password && !values.passwordCopy) {
    errors.passwordCopy = "Required";
  }
  if (values.password !== values.passwordCopy) {
    errors.passwordCopy = "Passwords do not match";
  }
  //confirmation password
  if (
    (values.password ||
      values.passwordCopy ||
      values.username ||
      values.favoriteTraning) &&
    !values.passwordOld
  ) {
    errors.passwordOld = "Required to update user";
  }

  return errors;
};

const AccountForm = ({ handleSubmit, errorFlag, updateUserCallback, user }) => {
  const [newImage, setNewImage] = useState("");

  const onSubmit = async (formValues) => {
    let data;
    if (newImage !== "") {
      //create name for profile image
      data = new FormData();
      const filename = Date.now() + newImage.name;
      data.append("name", filename);
      data.append("file", newImage);
      //add profile image name to form values
      formValues.profileImg = filename;
    }
    updateUserCallback(formValues, newImage, data);
  };

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      className="flex flex-col items-center h-fit p-2"
    >
      <h2 className="flex justify-center text-4xl mb-2 w-2/3 font-bold">
        Edit Profile
      </h2>
      <hr className="border-brown bg-red mb-4 w-4/5"></hr>
      <div className="flex flex-col w-2/3 ">
        <div>
          {newImage || user.profileImg ? (
            <div className="flex gap-2 items-center mb-4 ">
              <img
                className="h-40 w-40 rounded-full"
                src={
                  newImage
                    ? URL.createObjectURL(newImage)
                    : process.env.REACT_APP_IMAGE_URL + user.profileImg
                }
                alt=""
              />
            </div>
          ) : (
            <></>
          )}
          <label className="sm:w-full md:w-2/3 lg:w-1/2 my-4 py-2 flex justify-center rounded text-brown font-bold bg-whiteBrown border-2 border-brown flex hover:bg-darkGreen">
            <input
              className="hidden"
              type="file"
              id="fileInput"
              accept="image/png, image/gif, image/jpeg"
              onChange={(e) => setNewImage(e.target.files[0])}
            />
            Change profile image
          </label>
        </div>

        <div>
          <label className="text-xl ">Username</label>
          <Field
            name="username"
            type="text"
            component={wideRenderField}
            label="Edit your username"
          />
        </div>
        <div className="flex flex-col mb-4">
          <label className="text-xl  ">Email</label>
          <label className="text-lg text-black ">{user.email}</label>
        </div>
        <div>
          <label className="text-xl ">Favorite traning type</label>
          <TraningTypeDropdown name="favoriteTraning" />
        </div>
        <div>
          <label className="text-xl ">New Password</label>

          <Field
            name="password"
            type="password"
            component={wideRenderField}
            label="Enter a new password"
          />
          <Field
            name="passwordCopy"
            type="password"
            component={wideRenderField}
            label="Enter password again"
          />
        </div>
        <div>
          <label className="text-xl ">Confirm with current password</label>

          <Field
            name="passwordOld"
            type="password"
            component={wideRenderField}
            label="Confirm old password"
          />
        </div>
        <div className="w-max">
          <CustomButton
            buttonStyle="bg-whiteBrown border-2 border-brown hover:bg-lightGreen hover:text-white text-black"
            type="submit"
            buttonText={"Apply updates"}
          />
        </div>
      </div>
      <div className="w-2/3">
        {errorFlag && <ErrorMessage text={errorFlag} />}
      </div>
    </form>
  );
};

export default reduxForm({
  form: "accountform",
  validate,
})(AccountForm);
