import React from "react";

const ErrorMessage = ({ text }) => {
  return (
    <div className="w-full flex justify-center border-red border-2 bg-lightRed py-4 mb-4 rounded">
      {text}
    </div>
  );
};

export default ErrorMessage;
