import { Router } from "react-router-dom";
import React, { useState, useLayoutEffect } from "react";

//custom router and history object handle redirects after action dispatch
const CustomRouter = ({ history, ...props }) => {
  const [historyState, sethistoryState] = useState({
    action: history.action,
    location: history.location,
  });

  useLayoutEffect(() => history.listen(sethistoryState), [history]);

  return (
    <Router
      {...props}
      location={historyState.location}
      navigationType={historyState.action}
      navigator={history}
    />
  );
};

export default CustomRouter;
