import moment from "moment";

//get all the dates of a certain week
function getDatesOfWeek(weeknumber) {
  var dateformat = "YYYY-MM-DD";
  var date = moment()
      .isoWeek(weeknumber || 1)
      .startOf("isoweek"),
    weeklength = 7,
    result = [];
  while (weeklength--) {
    result.push(date.format(dateformat));
    date.add(1, "day");
  }
  return result;
}

//converts date to yyyy-mm-dd
function convertDate(date) {
  var convertDate = new Date(date),
    month = ("0" + (convertDate.getMonth() + 1)).slice(-2),
    day = ("0" + convertDate.getDate()).slice(-2);
  return [convertDate.getFullYear(), month, day].join("-");
}

export { getDatesOfWeek, convertDate };
