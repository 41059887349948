import React from "react";
import { reduxForm, Field } from "redux-form";

import CustomButton from "../../components/CustomButton";
import { renderTextArea } from "../utils/FieldComponents";
import TraningTypeDropdown from "../utils/TraningTypeDropdown";

const validate = (values) => {
  const errors = {};
  if (!values.description) {
    errors.description = "Required";
  } else if (values.description.length < 15) {
    errors.description = "Description needs to be at least 15 characters";
  }
  return errors;
};

const PageThreeForm = ({
  handleSubmit,
  previousPage,
  programImageCallback,
  file,
}) => {
  return (
    <form onSubmit={handleSubmit}>
      <div className="flex flex-col items-center gap-2">
        <div>
          {file && (
            <img
              className="max-h-96 rounded"
              src={URL.createObjectURL(file)}
              alt=""
            />
          )}
        </div>
        <label className="w-1/5 py-2 flex justify-center rounded bg-lightBrown border-2 border-brown flex hover:bg-brown">
          <input
            className="hidden"
            type="file"
            id="fileInput"
            accept="image/png, image/gif, image/jpeg"
            onChange={(e) => programImageCallback(e.target.files[0])}
          />
          Program Image
        </label>
      </div>
      <div>
        <label className="text-base">What kind of training is this?</label>
        <TraningTypeDropdown name="type" />
        <div>
          <label>Description</label>
          <div>
            <Field
              name="description"
              component={renderTextArea}
              styling="w-full max-h-screen outline-none p-4 rounded-lg h-96"
              placeholder="Describe what the program is in more detail"
            />
          </div>
        </div>
      </div>
      <div className="flex gap-4 w-full justify-center">
        <CustomButton
          buttonStyle="bg-lightBrown border-2 border-lightBrown hover:bg-brown hover:border-brown hover:text-white text-black"
          type="button"
          handleClick={previousPage}
          buttonText={"Previous"}
        />
        <CustomButton
          buttonStyle="bg-lightGreen border-2 border-lightGreen hover:bg-lightGreen hover:text-white text-black"
          type="submit"
          buttonText={"Show preview"}
        />
      </div>
    </form>
  );
};

export default reduxForm({
  form: "programform",
  validate,
  destroyOnUnmount: false,
})(PageThreeForm);
