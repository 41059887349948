import React from "react";

const LoadingScreen = () => {
  return (
    <div className="flex justify-center h-screen">
      <div
        className="spinner-border border-darkGreen animate-spin inline-block w-32 h-32 border-8 rounded-full mt-52"
        role="status"
      >
        <div className="rotate-90 bg-lightGreen">
          <span className="text-lightGreen">Loading...</span>
        </div>
      </div>
    </div>
  );
};

export default LoadingScreen;
