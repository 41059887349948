import React from "react";
import { compose } from "redux";
import { connect } from "react-redux";
import { Field, reduxForm, reset } from "redux-form";
import uniqid from "uniqid";

import { addExercise } from "../../../actions/ProgramActions";
import { renderField } from "../../utils/FieldComponents";
import CustomButton from "../../../components/CustomButton";

const validate = (values, props) => {
  const date = props.date;
  const errors = { [props.date]: {} };
  //exercise name is required
  if (values[date] && !values[date].exercise) {
    errors[date].exercise = "Required";
  }
  return errors;
};

const ExerciseForm = ({ date, handleSubmit, addExercise, exerciseNumber }) => {
  const onSubmit = async (formValues) => {
    //extract exercise object
    const { timePeriod, programTitle, ...exercise } = formValues;
    //get values of exercise
    const exerciseObject = exercise[date];
    //create a unique id
    const id = uniqid() + exerciseNumber;
    //add date, exercise number and unique id to exercise
    exerciseObject.date = date;
    exerciseObject.number = exerciseNumber;
    exerciseObject.id = id;
    addExercise(exerciseObject);
  };

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      className="w-full flex gap-2 justify-between border-b border-brown  p-2 mb-2"
    >
      <div className={"basis-2/6"}>
        <label className="text-base">Exercise</label>
        <Field
          name={`${date}.exercise`}
          type="text"
          component={renderField}
          label="Exercise name"
        />
      </div>
      <div className="basis-1/12">
        <label className="text-base">Reps</label>
        <Field
          name={`${date}.reps`}
          type="number"
          component={renderField}
          label="Reps"
        />
      </div>
      <div className="basis-1/12">
        <label className="text-base">Sets</label>
        <Field
          name={`${date}.sets`}
          type="number"
          component={renderField}
          label="Sets"
        />
      </div>
      <div className="basis-1/4">
        <label className="text-base">Rest between sets (sec)</label>
        <Field
          name={`${date}.rest`}
          type="number"
          component={renderField}
          label="Rest time"
        />
      </div>
      <div className="flex flex-col justify-end">
        <CustomButton
          buttonStyle="bg-lightGreen hover:bg-normalGreen hover:text-white"
          type="submit"
          buttonText={"Add"}
        />
      </div>
    </form>
  );
};

const mapStateToProps = (state, ownProps) => {
  let exerciseNumber = 1;
  //get number of existing exercises in workout
  // eslint-disable-next-line no-unused-vars
  for (const [key, value] of Object.entries(state.program)) {
    if (value.date === ownProps.date) {
      exerciseNumber++;
    }
  }
  //set unique form name based on date
  const formName = "exerciseform." + ownProps.date;

  return {
    formValues: state.form.programform,
    exerciseNumber: exerciseNumber,
    form: formName,
  };
};

//reset form dynamically based on date after form has been submitted
const afterSubmit = (result, dispatch, props) => {
  dispatch(reset(props.form));
};

export default compose(
  connect(mapStateToProps, { addExercise }),
  reduxForm({ onSubmitSuccess: afterSubmit, validate, destroyOnUnmount: false })
)(ExerciseForm);
