import React from "react";
import { connect } from "react-redux";
import { reduxForm } from "redux-form";

import CustomButton from "../../components/CustomButton";
import DayTemplate from "./pageTwoComponents/dayView/DayTemplate";
import WeekTemplate from "./pageTwoComponents/weekView/WeekTemplate";
import MonthTemplate from "./pageTwoComponents/monthView/MonthTemplate";

const PageTwoForm = ({
  handleSubmit,
  formValues,
  previousPage,
  previewFlag,
}) => {
  const programView = () => {
    switch (formValues.timePeriod) {
      case "week":
        return <WeekTemplate previewFlag={previewFlag} />;
      case "month":
        return <MonthTemplate previewFlag={previewFlag} />;
      default:
        return <DayTemplate previewFlag={previewFlag} />;
    }
  };

  return (
    <div>
      <div>{programView()}</div>
      <form onSubmit={handleSubmit}>
        {!previewFlag && (
          <div className="flex gap-4 w-full justify-center mt-4">
            <CustomButton
              buttonStyle="bg-lightBrown border-2 border-lightBrown hover:bg-brown hover:border-brown hover:text-white text-black"
              type="button"
              handleClick={previousPage}
              buttonText={"Previous"}
            />
            <CustomButton
              buttonStyle="bg-lightGreen border-2 border-lightGreen hover:bg-normalGreen hover:border-normalGreen hover:text-white text-black"
              type="submit"
              buttonText={"Next"}
            />
          </div>
        )}
      </form>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    formValues: state.form.programform.values,
  };
};

const PageTwoFormConnection = connect(mapStateToProps)(PageTwoForm);

export default reduxForm({
  form: "programform",
  destroyOnUnmount: false,
})(PageTwoFormConnection);
