import React from "react";
import { Field } from "redux-form";
import { ExperienceTypes } from "./ExperienceTypes";

const TraningExperienceDropdown = () => {
  return (
    <Field
      name="experience"
      component="select"
      className="py-2 w-2/4 border-lightGray rounded-lg border-2"
    >
      {ExperienceTypes.map((type) => {
        return (
          <option key={type.value} value={type.value}>
            {type.dropdownText}
          </option>
        );
      })}
    </Field>
  );
};

export default TraningExperienceDropdown;
