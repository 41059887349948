const renderField = ({ input, label, type, meta: { touched, error } }) => (
  <div>
    <div className="w-full border-b border-lightGray ">
      <div className="border-lightGray">
        <div>
          <input
            {...input}
            placeholder={label}
            type={type}
            className="rounded w-full outline-0 overflow-y-auto"
            min="1"
          />
        </div>
      </div>
    </div>
    {touched && error && <span className="text-red font-bold">{error}</span>}
  </div>
);

const wideRenderField = ({
  input,
  label,
  type,
  min,
  meta: { touched, error },
}) => (
  <div className="mb-8">
    <div className="border-b-2 border-lightGray">
      <div>
        <input
          {...input}
          placeholder={label}
          type={type}
          className="w-full p-4"
          min={min}
        />
      </div>
    </div>
    {touched && error && <span className="text-red font-bold">{error}</span>}
  </div>
);

const searchField = ({ input, label, type, min, text }) => (
  <div className="w-full flex gap-4 p-2">
    <div className="border-b-2 border-lightBrown w-full ">
      <input
        {...input}
        placeholder={label}
        type={type}
        className="w-full p-2 outline-none"
        min={min}
      />
    </div>
    <button
      type="submit"
      className="w-1/5 rounded bg-brown py-2 text-white font-bold"
    >
      {text}
    </button>
  </div>
);

const messageField = ({
  input,
  label,
  type,
  min,
  meta: { touched, error },
  text,
}) => (
  <div className="w-full flex flex-col">
    <div className="w-full flex gap-2 items-center justify-center mt-2 px-2">
      <div
        className={
          touched && error
            ? "border-red border-2 h-full rounded w-4/5"
            : "border border-2 h-full rounded w-4/5"
        }
      >
        <input
          {...input}
          placeholder={label}
          type={type}
          className="outline-none w-full p-2"
          min={min}
        />
      </div>
      <button
        type="submit"
        className="w-1/5 rounded bg-messageBlue py-2 h-full border-messageBlue border-2 text-white font-bold"
      >
        {text}
      </button>
    </div>
  </div>
);

const renderTextArea = ({
  input,
  meta: { touched, error },
  placeholder,
  styling,
}) => (
  <div>
    <textarea {...input} placeholder={placeholder} className={styling} />
    {touched && error && <span className="text-red font-bold">{error}</span>}
  </div>
);

export {
  renderField,
  renderTextArea,
  wideRenderField,
  messageField,
  searchField,
};
