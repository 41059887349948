import React from "react";
import { Field, reduxForm } from "redux-form";
import { Link } from "react-router-dom";
import CustomButton from "../components/CustomButton";
import ErrorMessage from "../components/ErrorMessage";
import { wideRenderField } from "./utils/FieldComponents";

const validate = (values) => {
  const errors = {};
  if (!values.username) {
    errors.username = "Required";
  }
  if (!values.password) {
    errors.password = "Required";
  }
  return errors;
};

const LoginForm = ({ handleSubmit, submitting, onSubmitForm, errorFlag }) => {
  const onSubmit = (formValues) => {
    onSubmitForm(formValues);
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="w-full">
      {errorFlag && (
        <ErrorMessage
          text={"Username or password is incorrect. Please try again!"}
        />
      )}
      <label className="text-xl">Username</label>
      <Field
        name="username"
        type="text"
        component={wideRenderField}
        label="Type you username"
      />
      <label className="text-xl">Password</label>
      <Field
        name="password"
        type="password"
        component={wideRenderField}
        label="Password"
      />
      <div className="my-4 flex gap-4 justify-center">
        <CustomButton
          buttonStyle="px-12 bg-lightBrown hover:bg-brown text-white"
          type="submit"
          disabled={submitting}
          buttonText={"Log in"}
          link={"/login"}
        />
      </div>
      <div>
        Don't have an account?{" "}
        <Link className="text-blue" to="/register">
          Register Now
        </Link>
      </div>
    </form>
  );
};

export default reduxForm({
  form: "loginform",
  validate,
})(LoginForm);
