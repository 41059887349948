import React from "react";
import { XCircleIcon } from "@heroicons/react/solid";

function BmiCard({ bmiScore, deleteBmiCallback }) {
  return (
    <div className="w-full flex gap-4 justify-between p-4 items-center border-brown border-2 rounded-3xl">
      <div>
        <span className="font-bold">BMI score: </span>
        {bmiScore.bmi}
      </div>
      <div>
        <span className="font-bold">Health: </span>
        {bmiScore.health}
      </div>
      <div>
        <span className="font-bold">Weight: </span>
        {bmiScore.weight} kg
      </div>
      <div>
        <span className="font-bold">Date: </span>
        {bmiScore.date}
      </div>
      <XCircleIcon
        className="block w-8 text-whiteBrown hover:text-red"
        aria-hidden="true"
        onClick={() => deleteBmiCallback(bmiScore._id)}
      />
    </div>
  );
}

export default BmiCard;
