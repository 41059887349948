import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import _ from "lodash";

import { httpGetUser } from "../apis/requestUser";
import { selectFriend } from "../actions/ChatActions";
import LoadingImg from "./LoadingImg";

const Friend = ({
  displayStatus,
  friendId,
  selectFriend,
  conversationId,
  online,
}) => {
  const [imageLoaded, setImageLoaded] = useState(false);
  const [friend, setFriend] = useState({});

  //get user profile
  useEffect(() => {
    const loadUser = async () => {
      const response = await httpGetUser(friendId);
      //add conversation id to object
      response.data.conversationId = conversationId;
      setFriend(response.data);
    };
    loadUser();
  }, [friendId, displayStatus, conversationId]);

  if (_.isEmpty(friend)) {
    return <div></div>;
  }

  return (
    <div
      className={`group flex w-full justify-left gap-4 items-center p-2 rounded ${
        imageLoaded && "hover:bg-lightBrown cursor-pointer"
      } `}
      onClick={() => selectFriend(friend)}
    >
      <div className="h-full flex justify-center items-center">
        {friend && friend.profileImg.length > 0 && (
          <img
            className="h-12 w-12 rounded-full"
            src={process.env.REACT_APP_IMAGE_URL + friend.profileImg}
            alt=""
            onLoad={() => setImageLoaded(true)}
          />
        )}
        {!imageLoaded && (
          <div className="ml-12">
            <LoadingImg size={12} color={"whiteBrown"} />
          </div>
        )}
        {displayStatus && imageLoaded && (
          <div className="w-5 h-5 rounded-full relative right-4 top-4 bg-whiteBrown group-hover:bg-lightBrown">
            <div
              className={`w-3 h-3 rounded-full relative p-1 m-1 ${
                online.includes(friend._id) ? "bg-limeGreen" : "bg-red"
              }`}
            ></div>
          </div>
        )}
      </div>
      {imageLoaded && <div>{friend.username}</div>}
    </div>
  );
};

const mapStateToProps = (state) => {
  const online = state.chat && state.chat.online ? state.chat.online : [];

  return {
    online,
  };
};

export default connect(mapStateToProps, { selectFriend })(Friend);
