import React from "react";
import {
  ChatIcon,
  ClipboardListIcon,
  CakeIcon,
} from "@heroicons/react/outline";

function Home() {
  return (
    <div className="min-h-screen h-max flex flex-col items-center">
      <div className="w-full h-screen-2/3">
        <div className=" bg-cover bg-homepage-1 object-cover h-full w-full text-white flex flex-col items-center justify-center gap-4">
          <h1 className="text-6xl font-bold">Welcome to RateFitness</h1>
          <div className="flex w-3/4 justify-center items-center gap-4 text-xl">
            <div className="flex gap-2 items-center">
              <div className="w-3 h-3 rounded-full bg-white" />
              <h2>Find progams</h2>
            </div>
            <div className="flex gap-2 items-center">
              <div className="w-3 h-3 rounded-full bg-white" />{" "}
              <h2>Share your own programs</h2>
            </div>
            <div className="flex gap-2 items-center">
              <div className="w-3 h-3 rounded-full bg-white" />{" "}
              <h2>Chat with friends</h2>
            </div>
            <div className="flex gap-2 items-center">
              <div className="w-3 h-3 rounded-full bg-white" />{" "}
              <h2>Get diet plan</h2>
            </div>
          </div>
        </div>
      </div>
      <div className="h-screen/3 w-full flex items-center bg-darkGreen p-8">
        <a
          href="/chat"
          className="flex flex-col justify-center items-center grow-1 w-full rounded h-max group"
        >
          <ChatIcon
            className="h-max block w-28 text-white group-hover:text-lightBrown"
            aria-hidden="true"
          />
          <h2 className="text-white font-bold text-xl group-hover:text-lightBrown">
            Chat with your friends
          </h2>
        </a>
        <a
          href="/programs"
          className="flex flex-col justify-center items-center grow-1 w-full rounded h-max group"
        >
          <ClipboardListIcon
            className="h-max block w-28 text-white group-hover:text-lightBrown"
            aria-hidden="true"
          />
          <h2 className="text-white font-bold text-xl group-hover:text-lightBrown">
            Find a program that fits you
          </h2>
        </a>
        <a
          href="/nutrition"
          className="flex flex-col justify-center items-center grow-1 w-full rounded h-max group"
        >
          <CakeIcon
            className="h-max block w-28 text-white group-hover:text-lightBrown"
            aria-hidden="true"
          />
          <h2 className="text-white font-bold text-xl group-hover:text-lightBrown">
            How much cake can I eat?
          </h2>
        </a>
      </div>
      <div className="w-full h-screen-2/3">
        <div className=" bg-cover bg-homepage-2 object-cover h-full w-full text-white flex flex-col items-center justify-center gap-8">
          <a
            href="/create-program"
            className="bg-darkGreen rounded p-8 bg-opacity-60 group"
          >
            <h1 className="text-6xl font-bold opacity-1 text-white group-hover:text-lightBrown">
              Create your own program
            </h1>
          </a>
        </div>
      </div>
    </div>
  );
}

export default Home;
