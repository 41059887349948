import React from "react";

const Macros = ({ protein, fat, carbs, selected }) => {
  return (
    <div className={selected ? "text-white flex gap-4" : "flex gap-4"}>
      <div>Protein: {Math.round(protein)} g</div>
      <div>Carbohydrates: {Math.round(carbs)} g</div>
      <div>Fat: {Math.round(fat)} g</div>
    </div>
  );
};

export default Macros;
