import React, { useEffect } from "react";
import { Route, Routes } from "react-router-dom";
import { connect } from "react-redux";

import "./css/index.css";
import { getUserSession } from "./actions/UserActions";
import Navbar from "./components/Navbar";
import Account from "./pages/Account";
import Home from "./pages/Home";
import Chat from "./pages/Chat";
import Programs from "./pages/Programs";
import Register from "./pages/Register";
import Login from "./pages/Login";
import ProgramForm from "./forms/programs/ProgramForm";
import Program from "./pages/Program";
import BmiCalculator from "./pages/BmiCalculator";
import Nutrition from "./pages/Nutrition";
import FindFriends from "./pages/FindFriends";
import Friends from "./pages/Friends";
import CustomRouter from "./routes/CustomRouter";
import ProtectedRoute from "./routes/ProtectedRoute";
import history from "./routes/history";
import OtherUserAccount from "./pages/OtherUserAccount";
import Health from "./pages/Health";
import Footer from "./components/Footer";

const App = ({ getUserSession, isSignedIn }) => {
  useEffect(() => {
    if (isSignedIn === null) {
      getUserSession();
    }
  }, [getUserSession, isSignedIn]);

  return (
    <div className="bg-lightGreen font-Oswald">
      <CustomRouter history={history}>
        <Navbar />
        <div className=" max-w-6xl mx-auto px-2 sm:px-6 lg:px-8">
          <Routes>
            <Route path="/" exact element={<Home />} />
            <Route path="/programs" exact element={<Programs />} />
            <Route path="/programs/:id" exact element={<Program />} />
            <Route path="/login" exact element={<Login />} />
            <Route path="/register" exact element={<Register />} />
            {/*protected routes*/}
            <Route exact path="/" element={<ProtectedRoute />}>
              <Route path="/chat" exact element={<Chat />} />
              <Route path="/account" exact element={<Account />} />
              <Route path="/create-program" exact element={<ProgramForm />} />
              <Route path="/bmi-calculator" exact element={<BmiCalculator />} />
              <Route path="/nutrition" exact element={<Nutrition />} />
              <Route path="/friends" exact element={<FindFriends />} />
              <Route path="/friends/:id" exact element={<Friends />} />
              <Route path="/health" exact element={<Health />} />
              <Route
                path="/friends/account/:id"
                exact
                element={<OtherUserAccount />}
              />
            </Route>
          </Routes>
        </div>
        <Footer />
      </CustomRouter>
    </div>
  );
};

const mapStateToProps = (state) => {
  return { isSignedIn: state.auth.isSignedIn };
};

export default connect(mapStateToProps, { getUserSession })(App);
