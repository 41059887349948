import React, { useState } from "react";
import { connect } from "react-redux";
import _ from "lodash";

import NutritionForm from "../forms/apis/NutritionForm";
import { httpGetMacros, httpSaveDiet } from "../apis/requestNutrition";
import Diets from "../components/Diets";
import CustomButton from "../components/CustomButton";
import ErrorMessage from "../components/ErrorMessage";
import LoadingScreen from "../components/LoadingScreen";

const Nutrition = ({ diet, isSignedIn, username, id }) => {
  const [macros, setMacros] = useState({});
  const [errorFlag, setErrorFlag] = useState("");

  const submitMacroForm = async (formValues) => {
    const response = await httpGetMacros(formValues);
    setMacros(response.data.data);
  };

  const selectDiet = async () => {
    if (isSignedIn && username.length > 0) {
      const response = await httpSaveDiet(diet, id);
      if (response.data && response.data.error) {
        setErrorFlag(response.data.error);
      } else {
        window.location.reload(true);
      }
    } else {
      setErrorFlag("Log in to save diet plan");
    }
  };

  if (!isSignedIn) {
    return <LoadingScreen />;
  }

  return (
    <div className="h-max min-h-screen w-full flex justify-center pb-10 mt-8">
      <div className="flex flex-col items-center w-2/3 gap-8 ">
        <div>
          <h1 className="text-white text-4xl flex justify-center w-full pb-4">
            Nutrition
          </h1>
          <div className="border-b-darkGreen border-b-4 p-2 text-white">
            Get daily calorie requirements for your health goals and select a
            macro plan that fits you. Whether you want low fat, low carb, high
            protein or simply balanced, we have you covered!
          </div>
        </div>
        <div className="w-full">
          <NutritionForm
            onSubmitForm={submitMacroForm}
            initialValues={{
              gender: "male",
              goal: "maintain",
              activitylevel: "level_1",
            }}
            formCalorieFlag={true}
          />
          {!_.isEmpty(macros) && (
            <div className="border-darkGreen rounded p-4 border-2 mt-4">
              <h1 className="text-2xl font-bold border-b border-white w-full mb-4">
                Diet plans:
              </h1>
              <div className="text-lg font-bold">
                Total calories: {Math.round(macros.calorie)}
              </div>
              <Diets macros={macros} />
              <div className="mt-4 w-full flex flex-col items-center justify-center">
                <CustomButton
                  buttonStyle="px-8 bg-normalGreen w-max hover:bg-darkGreen hover:text-white text-black"
                  type="button"
                  buttonText={"Save diet"}
                  handleClick={
                    diet.name.length > 0
                      ? () => selectDiet()
                      : () => setErrorFlag("Please select a diet")
                  }
                />
                <div className="w-1/4 mt-2">
                  {errorFlag && <ErrorMessage text={errorFlag} />}
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  const username = state.auth.isSignedIn ? state.auth.username : "";
  const id = state.auth.isSignedIn ? state.auth.id : "";

  return {
    isSignedIn: state.auth.isSignedIn,
    username: username,
    diet: state.diet,
    id: id,
  };
};

export default connect(mapStateToProps, {})(Nutrition);
