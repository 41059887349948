import React, { useState } from "react";
import { connect } from "react-redux";
import ReactStars from "react-rating-stars-component";
import { PencilAltIcon, TrashIcon } from "@heroicons/react/outline";

import ReviewForm from "../forms/ReviewForm";
import { httpDeleteReview } from "../apis/requestProgram";

const getExperience = (value) => {
  switch (value) {
    case "beginner":
      return "Beginner - I'm currently running it";
    case "intermediate":
      return " Intermediate - I have finished it";
    case "advanced":
      return "Advanced - I have finished it multiple times";
    default:
      return "None - I haven't tried it";
  }
};

const ReviewCard = ({ review, username }) => {
  const [editFlag, setEditFlag] = useState(false);

  const onDeleteReview = async () => {
    await httpDeleteReview(review._id, username);
    window.location.reload(true);
  };

  if (editFlag) {
    return (
      <div className="mb-2 w-full bg-whiteBrown py-2 px-4 border-t-2 border-brown flex flex-col gap-2">
        <ReviewForm
          initialValues={{
            opinion: review.opinion,
            experience: review.experience,
          }}
          previousRating={review.rating}
          editFlag={true}
          reviewId={review._id}
        />
      </div>
    );
  }

  return (
    <div className="mb-2 w-full bg-whiteBrown py-2 px-4 border-t-2 border-brown flex flex-col gap-2">
      <div className="flex flex-col sm:flew-col sm:items-start items-center">
        <div className="w-full flex justify-between items-center">
          <div className="flex gap-4">
            <ReactStars
              count={5}
              value={review.rating}
              size={24}
              edit={false}
              isHalf={true}
              emptyIcon={<i className="far fa-star"></i>}
              halfIcon={<i className="fa fa-star-half-alt"></i>}
              fullIcon={<i className="fa fa-star"></i>}
              activeColor="#ffd700"
            />
            {username === review.username && (
              <div className="flex">
                <PencilAltIcon
                  className="block w-6 text-lightBrown hover:text-brown"
                  aria-hidden="true"
                  onClick={() => setEditFlag(!editFlag)}
                />
                <TrashIcon
                  className="block w-6 text-lightRed hover:text-red"
                  aria-hidden="true"
                  onClick={() => onDeleteReview()}
                />
              </div>
            )}
          </div>
          <p className="text-lightGray">Written by: {review.username}</p>
        </div>
        <div className="flex flex-col justify-start sm:flex-row items-center">
          <span className="font-bold">Experience with program:</span>
          <span className=" ml-2 text-white bg-normalGreen rounded-2xl p-2 text-sm ">
            {getExperience(review.experience)}
          </span>
        </div>
      </div>
      <div>{review.opinion}</div>
    </div>
  );
};

const mapStateToProps = (state) => {
  if (state.auth.isSignedIn) {
    return { username: state.auth.username };
  }
  return { username: "" };
};

export default connect(mapStateToProps, {})(ReviewCard);
